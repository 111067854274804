import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import {
  PerPageOneHundred,
  PerPageThen,
} from "../../../Constants/Glopalconstants";

const initialState = {
  errorLogs: [],
  errorLogsLoading: true,

  projectLogs: [],
  projectLogsLoading: true,

  qpRequests: [],
  qpRequestsCount: 1,
  qpRequestsPage: 1,
  qpRequestsLoading: false,

  orgSessionList: {},
  orgSessionListLoading: true,

  useSessionList: [],
  useSessionListLoading: true,

  errors: {},
};

export const getErrorLogsAsync = createAsyncThunk(
  "errorLogs/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/error-logs?page=${action.page}&perPage=${PerPageOneHundred}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data?.errorLogs;
  }
);

export const getQpRequestsAsync = createAsyncThunk(
  "questionpro-requests/get",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.get(
        `${BASE_URL}/questionpro-requests?page=${action.page}&perPage=${PerPageThen}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteErrorLogsAsync = createAsyncThunk(
  "errorLogs/delete",
  async (_, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/error-logs`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteQpRequestsAsync = createAsyncThunk(
  "questionpro-requests/delete",
  async (_, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.delete(`${BASE_URL}/questionpro-requests`, {
        headers: {
          "x-feedback": feedbackId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getProjectLogsAsync = createAsyncThunk(
  "projectLogs/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/project-log?page=${action.page}&perPage=${PerPageOneHundred}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data?.projectLogs;
  }
);

export const deleteProjectLogsAsync = createAsyncThunk(
  "projectLogs/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(
      `${BASE_URL}/project-log?startDate=${action.startDate}&endDate=${action.endDate}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data?.projectLogs;
  }
);

export const getOrgSessionListAsync = createAsyncThunk(
  "orgSessionList/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/dashboard-analytics`,
      {
        ...action,
      },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getUserSessionListAsync = createAsyncThunk(
  "userSessionList/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/dashboard-analytics/user-session-list`,
      {
        ...action,
      },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetAdmin: () => initialState,
    sortErrorLog: (state, action) => {
      state.errorLogs = action.payload;
    },
    sortQpRequest: (state, action) => {
      state.qpRequests = action.payload;
    },
    sortPTRequest: (state, action) => {
      state.projectLogs = action.payload;
    },
    emptyPTRequest: (state, action) => {
      state.projectLogs = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getErrorLogsAsync.pending, (state, action) => {
        state.errorLogsLoading = true;
      })
      .addCase(getErrorLogsAsync.fulfilled, (state, action) => {
        state.errorLogs = action.payload;
        state.errorLogsLoading = false;
      })
      .addCase(getErrorLogsAsync.rejected, (state, action) => {
        state.errorLogsLoading = false;
        state.errors.errorlog = action.error.message;
      })
      .addCase(deleteErrorLogsAsync.fulfilled, (state, action) => {
        state.errorLogs = [];
      })
      .addCase(getQpRequestsAsync.pending, (state, action) => {
        state.errors = {};
        state.qpRequestsLoading = true;
      })
      .addCase(getQpRequestsAsync.fulfilled, (state, action) => {
        state.qpRequests = [...state.qpRequests, ...action.payload.requests];
        state.qpRequestsCount = action.payload.count;
        state.qpRequestsPage += 1;
        state.qpRequestsLoading = false;
      })
      .addCase(getQpRequestsAsync.rejected, (state, action) => {
        state.qpRequestsLoading = false;
        state.errors.qpRequests = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(deleteQpRequestsAsync.fulfilled, (state, action) => {
        state.qpRequests = [];
        state.qpRequestsCount = 0;
      })
      .addCase(getProjectLogsAsync.pending, (state, action) => {
        state.projectLogsLoading = true;
      })
      .addCase(getProjectLogsAsync.fulfilled, (state, action) => {
        state.projectLogs = action.payload;
        state.projectLogsLoading = false;
      })
      .addCase(getProjectLogsAsync.rejected, (state, action) => {
        state.projectLogsLoading = false;
        state.errors.projectlog = action.error.message;
      })
      .addCase(getOrgSessionListAsync.pending, (state, action) => {
        state.orgSessionListLoading = true;
      })
      .addCase(getOrgSessionListAsync.fulfilled, (state, action) => {
        state.orgSessionList = action.payload;
        state.orgSessionListLoading = false;
      })
      .addCase(getOrgSessionListAsync.rejected, (state, action) => {
        state.orgSessionListLoading = false;
        state.errors.orgSessionList = action.error.message;
      })
      .addCase(getUserSessionListAsync.pending, (state, action) => {
        state.useSessionListLoading = true;
      })
      .addCase(getUserSessionListAsync.fulfilled, (state, action) => {
        state.useSessionList = action.payload.sessions;
        state.useSessionListLoading = false;
      })
      .addCase(getUserSessionListAsync.rejected, (state, action) => {
        state.useSessionListLoading = false;
        state.errors.userSessionList = action.error.message;
      });
  },
});

export const {
  resetAdmin,
  sortErrorLog,
  sortQpRequest,
  sortPTRequest,
  emptyPTRequest,
} = adminSlice.actions;

//Selectors

export const getErrorLogs = (state) => state.admin.errorLogs;
export const getErrorLogsLoading = (state) => state.admin.errorLogsLoading;

export const getProjectLogs = (state) => state.admin.projectLogs;
export const getProjectLogsLoading = (state) => state.admin.projectLogsLoading;

export const getQpRequests = (state) => state.admin.qpRequests;
export const getQpRequestsCount = (state) => state.admin.qpRequestsCount;
export const getRequestsPage = (state) => state.admin.qpRequestsPage;
export const getQpRequestsLoading = (state) => state.admin.qpRequestsLoading;

export const getUserSessionList = (state) => state.admin.useSessionList;
export const getUserSessionListLoading = (state) =>
  state.admin.useSessionListLoading;
export const getOrgSessionList = (state) => state.admin.orgSessionList;
export const getOrgSessionListLoading = (state) =>
  state.admin.orgSessionListLoading;

export const getAdminErrors = (state) => state.admin.errors;

export default adminSlice.reducer;
