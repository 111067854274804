import { createSlice } from "@reduxjs/toolkit";
import { createTaskAsync, deleteTaskAsync, updateTaskAsync } from "../task";
import {
  createInitImpactGroupAsync,
  deleteAllDeletedInitiativesAsync,
  deleteInitImpactGroupAsync,
  updateInitImpactGroupAsync,
} from "../initiatives";
import {
  createInitiativeAsync,
  deleteInitiativeAsync,
  recoverInitiativeAsync,
  updateInitiativeAsync,
  updateInitiativeStatusAsync,
  updateInitiativeStatusHealthAsync,
} from "../initiative";
import {
  createBacklogAsync,
  deleteBacklogAsync,
  likeBacklogAsync,
  updateBacklogAsync,
} from "../backlog";
import { deleteErrorLogsAsync, deleteQpRequestsAsync } from "../admin";
import {
  checkCheckpointCommentAsync,
  createCheckpointAsync,
  createImpactOrEffortGroupAsync,
  createLaneAsync,
  createTagAsync,
  deleteCheckpointAsync,
  deleteImpactOrEffortGroupAsync,
  deleteLaneAsync,
  deleteTagAsync,
  getCheckpointCommentAsync,
  updateCheckpointAsync,
  updateImpactOrEffortGroupAsync,
  updateLaneAsync,
  updateTagsAsync,
} from "../settings";
import {
  createKPIAsync,
  deleteKPIAsync,
  updateKPIDateAsync,
  createMetricsData,
} from "../metricsKPI";
import { updateUserName } from "../user";
import { deleteHuddleAsync } from "../huddle";
import {
  creatMilestonesAsync,
  deleteMileStoneAsync,
  updateMileStoneAsync,
} from "../metrics";
import {
  createInitiativePeopleRoleAsync,
  deleteInitiativePeopleRoleAsync,
} from "../peopleRole";
import {
  createFishAnalyticsAsync,
  deleteFishAnalyticsAsync,
  updateFishAnalyticsAsync,
} from "../fishAnalysis";
import { addNewUserAsync } from "../outerloop";

const initialState = {
  requestSuccessesMessage: "",
  requestErrorMessage: "",
};

const successOrErrorSlice = createSlice({
  name: "successOrError",
  initialState,
  reducers: {
    resetStore: () => initialState,
    addSuccessMessage(state, action) {
      state.requestSuccessesMessage = action.payload;
    },
    addErrorMessage(state, action) {
      state.requestErrorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInitiativeAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Initiative successfully created";
      })
      .addCase(createInitiativeAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateInitiativeAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Initiative successfully updated";
      })
      .addCase(updateInitiativeAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteInitiativeAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Initiative successfully deleted";
      })
      .addCase(deleteInitiativeAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteAllDeletedInitiativesAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Initiatives successfully deleted";
      })
      .addCase(deleteAllDeletedInitiativesAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(recoverInitiativeAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Initiative successfully restored";
      })
      .addCase(recoverInitiativeAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(createInitImpactGroupAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Widget successfully created";
      })
      .addCase(createInitImpactGroupAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateInitImpactGroupAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Widget successfully updated";
      })
      .addCase(updateInitImpactGroupAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteInitImpactGroupAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Widget successfully deleted";
      })
      .addCase(deleteInitImpactGroupAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      //
      .addCase(createBacklogAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Backlog successfully created";
      })
      .addCase(createBacklogAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateBacklogAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Backlog successfully updated";
      })
      .addCase(updateBacklogAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteBacklogAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Backlog successfully deleted";
      })
      .addCase(deleteBacklogAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(likeBacklogAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      //
      .addCase(deleteErrorLogsAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Systems errors successfully deleted";
      })
      .addCase(deleteErrorLogsAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteQpRequestsAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Systems errors successfully deleted";
      })
      .addCase(deleteQpRequestsAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      //
      .addCase(createLaneAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Lane successfully created";
      })
      .addCase(createLaneAsync.rejected, (state, action) => {
        state.requestErrorMessage = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(updateLaneAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Lane successfully updated";
      })
      .addCase(updateLaneAsync.rejected, (state, action) => {
        state.requestErrorMessage = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(deleteLaneAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Lane successfully deleted";
      })
      .addCase(deleteLaneAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(createCheckpointAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Checkpoint successfully created";
      })
      .addCase(createCheckpointAsync.rejected, (state, action) => {
        state.requestErrorMessage = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(updateCheckpointAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = "Lane successfully updated";
      })
      .addCase(updateCheckpointAsync.rejected, (state, action) => {
        state.requestErrorMessage = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(deleteCheckpointAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Checkpoint successfully deleted";
      })
      .addCase(deleteCheckpointAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(createTagAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Tag successfully created";
      })
      .addCase(createTagAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateTagsAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Tag successfully updated";
      })
      .addCase(updateTagsAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteTagAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Tag successfully deleted";
      })
      .addCase(deleteTagAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(createImpactOrEffortGroupAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Tag groups successfully created";
      })
      .addCase(createImpactOrEffortGroupAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateImpactOrEffortGroupAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Tag groups successfully updated";
      })
      .addCase(updateImpactOrEffortGroupAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteImpactOrEffortGroupAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Tag groups successfully deleted";
      })
      .addCase(deleteImpactOrEffortGroupAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      //
      .addCase(createTaskAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Task successfully created";
      })
      .addCase(createTaskAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateTaskAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Task successfully updated";
      })
      .addCase(updateTaskAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteTaskAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Task successfully deleted";
      })
      .addCase(deleteTaskAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteHuddleAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Huddle successfully deleted";
      })
      .addCase(deleteHuddleAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(getCheckpointCommentAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(checkCheckpointCommentAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Thank you for the experience.";
      })
      .addCase(checkCheckpointCommentAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(creatMilestonesAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Milestone successfully created";
      })
      .addCase(creatMilestonesAsync.rejected, (state, action) => {
        state.requestErrorMessage = `${
          action.payload?.message || "Something went wrong"
        }`;
      })
      .addCase(updateMileStoneAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Milestone successfully updated";
      })
      .addCase(updateMileStoneAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteMileStoneAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Milestone successfully deleted";
      })
      .addCase(deleteMileStoneAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(createInitiativePeopleRoleAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "User role successfully added";
      })
      .addCase(createInitiativePeopleRoleAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteInitiativePeopleRoleAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "User role successfully deleted";
      })
      .addCase(deleteInitiativePeopleRoleAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(createFishAnalyticsAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Cause successfully added!";
      })
      .addCase(createFishAnalyticsAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateFishAnalyticsAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Cause successfully updated!";
      })
      .addCase(updateFishAnalyticsAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })

      .addCase(deleteFishAnalyticsAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "Cause successfully deleted";
      })
      .addCase(deleteFishAnalyticsAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })

      //
      .addCase(updateInitiativeStatusAsync.fulfilled, (state) => {
        state.requestSuccessesMessage =
          "Initiative status successfully updated";
      })
      .addCase(updateInitiativeStatusAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(updateInitiativeStatusHealthAsync.fulfilled, (state) => {
        state.requestSuccessesMessage =
          "Initiative healthStatus successfully updated";
      })
      .addCase(updateInitiativeStatusHealthAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      //
      .addCase(createMetricsData.fulfilled, (state) => {
        state.requestSuccessesMessage = "Metrics items successfully created";
      })
      .addCase(createMetricsData.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      // .addCase(updateMetricsAsync.fulfilled, (state) => {
      // 	state.requestSuccessesMessage = "CreateOrEditKPIMetricsMetricsItem successfully updated";
      // })
      // .addCase(updateMetricsAsync.rejected, (state) => {
      // 	state.requestErrorMessage = "Something went wrong";
      // })
      // .addCase(deleteMetricsAsync.fulfilled, (state) => {
      // 	state.requestSuccessesMessage = "CreateOrEditKPIMetricsMetricsItem successfully deleted";
      // })
      // .addCase(deleteMetricsAsync.rejected, (state) => {
      // 	state.requestErrorMessage = "Something went wrong";
      // })
      .addCase(createKPIAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "KPI successfully created";
      })
      .addCase(createKPIAsync.rejected, (state, action) => {
        state.requestErrorMessage =
          action.payload?.error?.message || "Something went wrong";
      })
      .addCase(updateKPIDateAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "KPI date successfully updated";
      })
      .addCase(updateKPIDateAsync.rejected, (state, action) => {
        state.requestErrorMessage =
          action.payload?.error?.message || "Something went wrong";
      })
      .addCase(deleteKPIAsync.fulfilled, (state) => {
        state.requestSuccessesMessage = "KPI successfully deleted";
      })
      .addCase(deleteKPIAsync.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      //
      .addCase(updateUserName.fulfilled, (state) => {
        state.requestSuccessesMessage = "User name successfully updated";
      })
      .addCase(updateUserName.rejected, (state) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(addNewUserAsync.fulfilled, (state) => {
        state.requestSuccessesMessage =
          "The user has been added to your organization";
      })
      .addCase(addNewUserAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      });
  },
});

export const { resetStore, addSuccessMessage, addErrorMessage } =
  successOrErrorSlice.actions;

//Selectors
export const getRequestSuccessesMessage = (state) =>
  state.successOrError.requestSuccessesMessage;
export const getRequestErrorMessage = (state) =>
  state.successOrError.requestErrorMessage;

export default successOrErrorSlice.reducer;
