import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, TOKEN_NAME } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { removeCookies } from "../../../Utils/cookies";

const initialState = {
  user: [],
  userLoading: true,

  selectUser: null,

  productSwitcher: [],
  headerInfo: {},
  feedbacks: [],
  feedbackLoading: true,
  feedbacksPage: false,

  selectFeedbackName: null,
  selectedFeedback: {},
  emailDisabled: true,

  importInitiativeLoading: false,
  importedInitiative: null,
  importedInitiativeTableData: [],
  errors: {},
};

export const getUserAsync = createAsyncThunk(
  "user/get",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const response = await axios.get(`${BASE_URL}/oauth/users/me`);
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getProductSwitcherAccessAsync = createAsyncThunk(
  "/product-switcher/get",
  async (_, state) => {
    try {
      const startTime = new Date().getTime();
      const response = await axios.get(`${BASE_URL}/product-switcher`);
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getFeedbackAsync = createAsyncThunk(
  "feedbacks/get",
  async (_, state) => {
    try {
      const startTime = new Date().getTime();
      const user = state.getState().user?.user;
      const response = await axios.get(`${BASE_URL}/feedbacks`);
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return { data: response.data, feedbackId: user.lastVisitedFeedbackID };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const importInitiativeAsync = createAsyncThunk(
  "initiative-import",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.post(
        `${BASE_URL}/initiative/import`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const emailDisabledAsync = createAsyncThunk(
  "feedbacks/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/user-settings`,
      { emailDisabled: action.status },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateUserName = createAsyncThunk(
  "updateUserName/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/user-settings/username`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    selectFeedbackName: (state, action) => {
      state.selectFeedbackName = action.payload;
    },
    sortFeedback: (state, action) => {
      state.feedbacks = action.payload;
    },
    goFeedbacksPage: (state) => {
      state.feedbacksPage = true;
    },
    resetImportedFields: (state) => {
      state.importedInitiative = null;
      state.importInitiativeLoading = false;
    },
    saveImportedInitiativeTableData: (state, action) => {
      state.importedInitiativeTableData = action.payload;
    },
    setSelectFeedback: (state, action) => {
      state.selectedFeedback = action.payload;
    },
    setSelectUser: (state, action) => {
      state.selectUser = action.payload;
    },
    changeLastVisitedFeedbackID: (state, action) => {
      state.user.lastVisitedFeedbackID = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state) => {
        state.userLoading = true;
        state.errors = {};
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userLoading = false;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.userLoading = false;

        if (+action.payload?.statusCode !== 401) {
          state.errors.user =
            action.payload?.statusCode && action.payload?.message
              ? `${action.payload?.statusCode} - ${action.payload?.message}`
              : `Server Error`;
        }
      })
      .addCase(getProductSwitcherAccessAsync.pending, () => {})
      .addCase(getProductSwitcherAccessAsync.fulfilled, (state, action) => {
        const permissions =
          action.payload.headerInfo[0] &&
          action.payload.headerInfo[0].productSwitcher?.categories;
        permissions?.forEach((products) => {
          if (products?.name === "Customer Experience" && !products?.active) {
            removeCookies(TOKEN_NAME);
            window.location.href =
              "https://www.questionpro.com/a/showCXFeedback.do";
          }
          if (products?.name === "Customer Experience") {
            products?.product?.forEach((product) => {
              if (product.name === "Outerloop" && !product.active) {
                removeCookies(TOKEN_NAME);
                window.location.href =
                  "https://www.questionpro.com/a/showCXFeedback.do";
              }
            });
          }
        });
        state.productSwitcher = permissions;
        state.headerInfo = {
          categories: action.payload?.headerInfo[0].productSwitcher?.categories,
          myAccount: action.payload?.headerInfo[1]?.myAccount,
          footer: action.payload?.headerInfo[2]?.footer,
        };
      })
      .addCase(getProductSwitcherAccessAsync.rejected, () => {})
      .addCase(getFeedbackAsync.pending, (state) => {
        state.feedbackLoading = true;
        state.errors = {};
      })
      .addCase(getFeedbackAsync.fulfilled, (state, action) => {
        const feedbacks = action.payload.data.response;
        const feedback = feedbacks.find(
          (feedback) => feedback?.feedbackID === action.payload.feedbackId
        );
        state.feedbacks = feedbacks;
        state.selectFeedbackName = feedback?.feedbackName || null;
        state.selectedFeedback = feedback || {};
        state.feedbackLoading = false;
      })
      .addCase(getFeedbackAsync.rejected, (state, action) => {
        state.feedbackLoading = false;
        if (action.payload.statusCode !== 403) {
          state.errors.feedback = `${action.payload?.statusCode} - ${action.payload?.message}`;
        }
      })
      .addCase(importInitiativeAsync.pending, (state) => {
        state.errors = {};
        state.importInitiativeLoading = true;
        state.importedInitiative = null;
      })
      .addCase(importInitiativeAsync.fulfilled, (state, action) => {
        state.importInitiativeLoading = false;
        state.importedInitiative = action.payload;
      })
      .addCase(importInitiativeAsync.rejected, (state) => {
        state.importInitiativeLoading = false;
        state.importedInitiative = null;
        state.errors.importInitiativeError = `Something went wrong`;
      });
  },
});

export const {
  selectFeedbackName,
  sortFeedback,
  goFeedbacksPage,
  resetImportedFields,
  saveImportedInitiativeTableData,
  setSelectFeedback,
  setSelectUser,
  changeLastVisitedFeedbackID
} = userSlice.actions;

//Selectors
export const getCurrentUser = (state) => state.user.user;
export const getSelectUser = (state) => state.user.selectUser;
export const getUserCurrentFeedback = (state) => state.user.selectedFeedback;
export const getSelectFeedbackName = (state) => state.user.selectFeedbackName;
export const getProductSwitcher = (state) => state.user.productSwitcher;
export const getImportInitiativeLoading = (state) =>
  state.user.importInitiativeLoading;
export const getImportedInitiative = (state) => state.user.importedInitiative;
export const getUserLoading = (state) => state.user.userLoading;
export const getFeedbacks = (state) => state.user.feedbacks;
export const getFeedbackLoading = (state) => state.user.feedbackLoading;
export const getFeedbacksPage = (state) => state.user.feedbacksPage;

export const getUserErrors = (state) => state.user.errors;

export const getImportedInitiativeTableData = (state) =>
  state.user.importedInitiativeTableData;
export const headerInfoSelector = (state) => state.user.headerInfo;

export default userSlice.reducer;
