import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLastVisitedFeedbackID } from "../../Store/slices/user";

const FeedbacksNavigationHandler = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLastVisitedFeedbackID(null));
    navigate("/feedbacks");
  }, [dispatch, navigate, pathname]);
};

export default FeedbacksNavigationHandler;
