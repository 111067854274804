import "./Avatar.scss";
import { memo } from "react";
import { setSelectUser } from "../../../Store/slices/user";
import { useDispatch } from "react-redux";

const Avatar = ({
  people,
  status,
  index = 1,
  type = "checkbox",
  onSelect = () => {},
  selectTeam,
}) => {
  const dispatch = useDispatch();
  const selectUser = () => {
    dispatch(
      setSelectUser({
        userID: people?.userID || people?.id,
        emailAddress: people?.emailAddress,
      })
    );
  };

  return (
    <div className={"avatar-block"}>
      {status?.checked && (
        <input
          checked={selectTeam?.some(
            (selectTeam) => selectTeam?.emailAddress === people?.emailAddress
          )}
          type={type}
          id={people?.id + index || people?.emailAddress + index}
          onChange={() => onSelect(people)}
        />
      )}
      <div
        style={{ backgroundColor: people?.color ? people?.color : "#FFA86D" }}
        className={"avatar-block--name"}
        onClick={selectUser}
      >
        { people?.username?.charAt(0).toUpperCase()  || people?.emailAddress?.charAt(0).toUpperCase() || ""}
      </div>
      {status?.email && (
        <label
          className={"avatar-block--email"}
          htmlFor={people?.id + index || people?.emailAddress + index}
        >
          {people?.username || people?.emailAddress}
        </label>
      )}
    </div>
  );
};

export default memo(Avatar);
