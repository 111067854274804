import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import {
  AUTH_URL,
  CALLBACK_URL,
  CLIENT_ID,
  TOKEN_NAME,
} from "../../Constants/Api";
import LoadingPage from "../../Pages/LoadingPage/index";
import NoPermission from "../../Components/Reusable/NoPermission/NoPermission";
import {
  getUserAsync,
  getFeedbackAsync,
  getUserErrors,
  getFeedbackLoading,
  getUserLoading,
  getProductSwitcherAccessAsync,
  getSelectUser,
} from "../../Store/slices/user";
import {
  disconnectSocket,
  initiateSocketConnection,
} from "../../socketio.service";
import BaseHeader from "../../Components/Header/BaseHeader";
import ShowToasterMessage from "../../Components/Reusable/ShowToasterMessage/ShowToasterMessage";
import UserLogHistoryModal from "../../Components/Reusable/LogHistory/UserLogHistoryModal";
import axios from "axios";
import { getCookies, removeCookies } from "../../Utils/cookies";

const BaseLayout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const selectUser = useSelector(getSelectUser);
  const userLoading = useSelector(getUserLoading);
  const feedbackLoading = useSelector(getFeedbackLoading);
  const errors = useSelector(getUserErrors);
  const token = getCookies(TOKEN_NAME);
  const redirectToLogin = () => {
    window.location.href = `${AUTH_URL}/?state=null&redirect_uri=${CALLBACK_URL}&response_type=code&client_id=${CLIENT_ID}`;
  };

  axios.interceptors.request.use(function (config) {
    config.headers = {
      ...config.headers,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const getUser = useCallback(() => {
    if (token) {
      initiateSocketConnection();
      dispatch(getUserAsync()).then((response) => {
        if (response.payload?.data) {
          dispatch(getProductSwitcherAccessAsync());
          dispatch(getFeedbackAsync());
        }
        if (response.payload?.statusCode === 401) {
          removeCookies(TOKEN_NAME);
          redirectToLogin();
        }
      });
    }
    if (!token) {
      redirectToLogin();
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (pathname?.length > 1 && pathname.includes("initiative")) {
      localStorage.setItem("pathname", JSON.stringify(pathname));
    }
    getUser();
  }, [getUser]);

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, []);

  if (errors.user || errors.feedback) {
    return (
      <>
        <BaseHeader />
        <div className={"error-message"}>{errors.user || errors.feedback}</div>
      </>
    );
  }

  if (userLoading || feedbackLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <ShowToasterMessage />
      {selectUser && <UserLogHistoryModal user={selectUser} />}
      {errors.permission ? (
        <NoPermission />
      ) : (
        <>
          <BaseHeader />
          <main className="main-container">
            <Outlet />
          </main>
        </>
      )}
    </>
  );
};

export default BaseLayout;
