import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";

const initialState = {
  fishAnalyticsSettings: null,
  fishAnalyticsSettingsLoading: true,
  fishAnalyticsSettingsError: "",

  fishAnalytics: null,
  fishAnalyticsLoading: true,
  fishAnalyticsError: "",
};

export const getFishAnalyticsSettingsAsync = createAsyncThunk(
  "fishAnalyticsSettings/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(`${BASE_URL}/fishbone-settings`, {
        headers: {
          "x-feedback": feedbackId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const updateFishAnalyticsSettingsAsync = createAsyncThunk(
  "fishAnalyticsSettings/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.patch(
        `${BASE_URL}/fishbone-settings`,
        {
          ...action,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getFishAnalyticsAsync = createAsyncThunk(
  "analytics/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/fishbone-analysis/initiative/${action.initID}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const createFishAnalyticsAsync = createAsyncThunk(
  "analytics/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/fishbone-analysis`,
        {
          ...action,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const updateFishAnalyticsAsync = createAsyncThunk(
  "analytics/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.patch(
        `${BASE_URL}/fishbone-analysis/${action.id}`,
        {
          ...action,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteFishAnalyticsAsync = createAsyncThunk(
  "analytics/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.delete(
        `${BASE_URL}/fishbone-analysis/${action.id}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const createFishAnalyticsCommentAsync = createAsyncThunk(
  "analyticsComment/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/fishbone-comments`,
        {
          ...action,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteFishAnalyticsCommentAsync = createAsyncThunk(
  "analyticsComment/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.delete(
        `${BASE_URL}/fishbone-comments/${action.commentId}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

const rowType = {
  0: "topRow",
  1: "topRow",
  2: "topRow",
  3: "bottomRow",
  4: "bottomRow",
  5: "bottomRow",
};

const fishAnalyticsSlice = createSlice({
  name: "fishAnalytics",
  initialState,
  reducers: {
    resetFishAnalytics: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFishAnalyticsSettingsAsync.pending, (state, action) => {
        state.fishAnalyticsSettingsLoading = true;
      })
      .addCase(getFishAnalyticsSettingsAsync.fulfilled, (state, action) => {
        state.fishAnalyticsSettings = action.payload;
        state.fishAnalyticsSettingsLoading = false;
      })
      .addCase(getFishAnalyticsSettingsAsync.rejected, (state, action) => {
        state.fishAnalyticsSettingsLoading = false;
        state.fishAnalyticsSettingsError = action.payload.message;
      })
      .addCase(getFishAnalyticsAsync.pending, (state, action) => {
        state.fishAnalyticsLoading = true;
      })
      .addCase(getFishAnalyticsAsync.fulfilled, (state, action) => {
        state.fishAnalytics = action.payload;
        state.fishAnalyticsLoading = false;
      })
      .addCase(getFishAnalyticsAsync.rejected, (state, action) => {
        state.fishAnalyticsLoading = false;
        state.fishAnalyticsError = action.payload.message;
      })

      .addCase(createFishAnalyticsAsync.fulfilled, (state, action) => {
        let newRow = [];
        if (action.payload.index) {
          newRow = state.fishAnalytics[rowType[action.payload.index]].map(
            (item) => {
              if (item.index === action.payload.index) {
                return {
                  ...item,
                  data: [
                    {
                      id: action.payload.id,
                      note: action.payload.note,
                      description: action.payload.description,
                      comments: [],
                    },
                    ...item.data,
                  ],
                };
              }
              return item;
            }
          );
        } else {
          newRow = state.fishAnalytics[rowType[0]].map((item) => {
            if (item.index === 0) {
              return {
                ...item,
                data: [
                  ...action.payload.analysis.map((el) => ({
                    id: el.id,
                    people: el.user,
                    comments: [],
                  })),
                  ...item.data,
                ],
              };
            }
            return item;
          });
        }

        state.fishAnalytics = {
          ...state.fishAnalytics,
          [rowType[action.payload.index || 0]]: newRow,
        };
      })
      .addCase(updateFishAnalyticsAsync.fulfilled, (state, action) => {
        if (action.payload.index === 7) {
          state.fishAnalytics.finalBranch.name = action.payload?.type;
        } else {
          const newRow = state.fishAnalytics[rowType[action.payload.index]].map(
            (item) => {
              if (item.index === action.payload.index) {
                return {
                  ...item,
                  data: item.data.map((el) => {
                    if (el.id === action.payload.id) {
                      el.note = action.payload.note;
                      el.description = action.payload.description;
                    }
                    return el;
                  }),
                };
              }
              return item;
            }
          );

          state.fishAnalytics = {
            ...state.fishAnalytics,
            [rowType[action.payload.index]]: newRow,
          };
        }
      })
      .addCase(deleteFishAnalyticsAsync.fulfilled, (state, action) => {
        const newRow = state.fishAnalytics[rowType[action.payload.index]].map(
          (item) => {
            if (item.index === action.payload.index) {
              return {
                ...item,
                data: item.data.filter((el) => el.id !== action.payload.id),
              };
            }
            return item;
          }
        );

        state.fishAnalytics = {
          ...state.fishAnalytics,
          [rowType[action.payload.index]]: newRow,
        };
      })
      .addCase(createFishAnalyticsCommentAsync.fulfilled, (state, action) => {
        const newRow = state.fishAnalytics[rowType[action.payload.index]].map(
          (item) => {
            if (item.index === action.payload.index) {
              return {
                ...item,
                data: item.data.map((el) => {
                  if (el.id === action.payload.fishboneAnalysisId) {
                    el.comments = [action.payload, ...el.comments];
                    el.isCommented = true;
                  }
                  return el;
                }),
              };
            }
            return item;
          }
        );

        state.fishAnalytics = {
          ...state.fishAnalytics,
          [rowType[action.payload.index]]: newRow,
        };
      })
      .addCase(deleteFishAnalyticsCommentAsync.fulfilled, (state, action) => {
        const newRow = state.fishAnalytics[rowType[action.payload.index]].map(
          (item) => {
            if (item.index === action.payload.index) {
              return {
                ...item,
                data: item.data.map((el) => {
                  if (el.id === action.payload.fishboneAnalysisId) {
                    el.comments = el.comments.filter(
                      (comment) => comment.id !== action.payload.id
                    );
                    el.isCommented = false;
                  }
                  return el;
                }),
              };
            }
            return item;
          }
        );

        state.fishAnalytics = {
          ...state.fishAnalytics,
          [rowType[action.payload.index]]: newRow,
        };
      });
  },
});

export const { resetFishAnalytics } = fishAnalyticsSlice.actions;

export const getFishAnalyticsSettings = (state) =>
  state.fishAnalytics.fishAnalyticsSettings;
export const getFishAnalyticsSettingsLoading = (state) =>
  state.fishAnalytics.fishAnalyticsSettingsLoading;
export const getFishAnalyticsSettingsError = (state) =>
  state.fishAnalytics.fishAnalyticsSettingsError;

export const getFishAnalytics = (state) => state.fishAnalytics.fishAnalytics;
export const getFishAnalyticsLoading = (state) =>
  state.fishAnalytics.fishAnalyticsLoading;
export const getFishAnalyticsError = (state) =>
  state.fishAnalytics.fishAnalyticsError;

export default fishAnalyticsSlice.reducer;
