import InfiniteScroll from 'react-infinite-scroller'
import './ScrollPagination.scss'

const ScrollPagination = ({ children = '', loadMore, hasMore, useWindow = false, column=true }) => {
  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        column > 7 && (
          <div className={'scroll-pagination--loading'} key={0}>
            Loading ...
          </div>
        )
      }
      useWindow={useWindow}
    >
      {children}
    </InfiniteScroll>
  )
}


export default ScrollPagination
