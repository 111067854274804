import "./MainLayout.scss";
import InitiativeBar from "../../Components/InitiativeBar/InitiativeBar";

const MainLayout = ({ children }) => {
  return (
    <div className={"main-layout"}>
      <InitiativeBar />
      <div className={"main-layout--children"}>{children}</div>
    </div>
  );
};

export default MainLayout;
