import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { PerPageThen } from "../../../Constants/Glopalconstants";

const initialState = {
  analytics: [],
  analyticsCount: 1,
  analyticsPage: 1,
  analyticsLoading: false,
  analyticsError: "",

  initiativeOwnerAnalytics: [],
  initiativeOwnerAnalyticsMonths: [],
  initiativeOwnerAnalyticsCount: 0,
  initiativeOwnerAnalyticsLoading: true,
  initiativeOwnerAnalyticsError: "",

  initiativeContributorAnalytics: [],
  initiativeContributorAnalyticsMonths: [],
  initiativeContributorAnalyticsCount: 0,
  initiativeContributorAnalyticsLoading: true,
  initiativeContributorAnalyticsError: "",

  taskOwnerAnalytics: [],
  taskOwnerAnalyticsCount: 0,
  taskOwnerAnalyticsLoading: true,
  taskOwnerAnalyticsError: "",

  taskContributorAnalytics: [],
  taskContributorAnalyticsCount: 0,
  taskContributorAnalyticsLoading: true,
  taskContributorAnalyticsError: "",
};

export const getAnalyticsAsync = createAsyncThunk(
  "analytics/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(`${BASE_URL}/dashboard/analytics`, {
        headers: {
          "x-feedback": feedbackId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getUserAnalyticsAsync = createAsyncThunk(
  "userAnalytics/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/user-analytics?offset=${action.offset}&limit=${PerPageThen}`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return { data: response.data, type: action.type };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue({
        data: err.response.data,
        type: action.type,
      });
    }
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    resetAnalytics: () => initialState,
    resetInitAnalytics: (state) => {
      state.initiativeOwnerAnalytics = [];
      state.initiativeOwnerAnalyticsMonths = [];
      state.initiativeOwnerAnalyticsCount = 0;
      state.initiativeOwnerAnalyticsLoading = true;
      state.initiativeOwnerAnalyticsError = "";

      state.initiativeContributorAnalytics = [];
      state.initiativeContributorAnalyticsMonths = [];
      state.initiativeContributorAnalyticsCount = 0;
      state.initiativeContributorAnalyticsLoading = true;
      state.initiativeContributorAnalyticsError = "";
    },
    resetTaskAnalytics: (state) => {
      state.taskOwnerAnalytics = [];
      state.taskOwnerAnalyticsCount = 0;
      state.taskOwnerAnalyticsLoading = true;
      state.taskOwnerAnalyticsError = "";

      state.taskContributorAnalytics = [];
      state.taskContributorAnalyticsCount = 0;
      state.taskContributorAnalyticsLoading = true;
      state.taskContributorAnalyticsError = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalyticsAsync.pending, (state, action) => {
        state.analyticsLoading = true;
      })
      .addCase(getAnalyticsAsync.fulfilled, (state, action) => {
        state.analytics = action.payload.users;
        state.analyticsLoading = false;
      })
      .addCase(getAnalyticsAsync.rejected, (state, action) => {
        state.analyticsLoading = false;
        state.analyticsError = "Something went wrong";
      })
      .addCase(getUserAnalyticsAsync.pending, (state, action) => {
        state.initiativeOwnerAnalyticsLoading = true;
        state.initiativeContributorAnalyticsLoading = true;
        state.taskOwnerAnalyticsLoading = true;
        state.taskContributorAnalyticsLoading = true;
      })
      .addCase(getUserAnalyticsAsync.fulfilled, (state, action) => {
        if (action.payload.data.initiatives) {
          if (action.payload.type === "initiative_owner") {
            state.initiativeOwnerAnalytics = action.payload.data.initiatives;
            state.initiativeOwnerAnalyticsMonths = action.payload.data.months;
            state.initiativeOwnerAnalyticsCount = action.payload.data.count;
            state.initiativeOwnerAnalyticsLoading = false;
          }
          if (action.payload.type === "initiative_contributor") {
            state.initiativeContributorAnalytics =
              action.payload.data.initiatives;
            state.initiativeContributorAnalyticsMonths =
              action.payload.data.months;
            state.initiativeContributorAnalyticsCount =
              action.payload.data.count;
            state.initiativeContributorAnalyticsLoading = false;
          }
        }

        if (action.payload.data.tasks) {
          if (action.payload.type === "task_owner") {
            state.taskOwnerAnalytics = action.payload.data.tasks;
            state.taskOwnerAnalyticsCount = action.payload.data.count;
            state.taskOwnerAnalyticsLoading = false;
          }
          if (action.payload.type === "task_contributor") {
            state.taskContributorAnalytics = action.payload.data.tasks;
            state.taskContributorAnalyticsCount = action.payload.data.count;
            state.taskContributorAnalyticsLoading = false;
          }
        }
      })
      .addCase(getUserAnalyticsAsync.rejected, (state, action) => {
        if (action.payload.type === "initiative_owner") {
          state.initiativeOwnerAnalyticsLoading = false;
          state.initiativeOwnerAnalyticsError = "Something went wrong";
        }
        if (action.payload.type === "initiative_contributor") {
          state.initiativeContributorAnalyticsLoading = false;
          state.initiativeContributorAnalyticsError = "Something went wrong";
        }
        if (action.payload.type === "task_owner") {
          state.taskOwnerAnalyticsLoading = false;
          state.taskOwnerAnalyticsError = "Something went wrong";
        }
        if (action.payload.type === "task_contributor") {
          state.taskContributorAnalyticsLoading = false;
          state.taskContributorAnalyticsError = "Something went wrong";
        }
      });
  },
});

export const { resetAnalytics, resetInitAnalytics, resetTaskAnalytics } =
  analyticsSlice.actions;

export const getAnalytics = (state) => state.analytics.analytics;
export const getAnalyticsCount = (state) => state.analytics.analyticsCount;
export const getAnalyticsPage = (state) => state.analytics.analyticsPage;
export const getAnalyticsLoading = (state) => state.analytics.analyticsLoading;
export const getAnalyticsError = (state) => state.analytics.analyticsError;

export const getInitiativeOwnerAnalytics = (state) =>
  state.analytics.initiativeOwnerAnalytics;
export const getInitiativeOwnerAnalyticsMonths = (state) =>
  state.analytics.initiativeOwnerAnalyticsMonths;
export const getInitiativeOwnerAnalyticsCount = (state) =>
  state.analytics.initiativeOwnerAnalyticsCount;
export const getInitiativeOwnerAnalyticsLoading = (state) =>
  state.analytics.initiativeOwnerAnalyticsLoading;
export const getInitiativeOwnerAnalyticsError = (state) =>
  state.analytics.initiativeOwnerAnalyticsError;

export const getInitiativeContributorAnalytics = (state) =>
  state.analytics.initiativeContributorAnalytics;
export const getInitiativeContributorAnalyticsMonths = (state) =>
  state.analytics.initiativeContributorAnalyticsMonths;
export const getInitiativeContributorAnalyticsCount = (state) =>
  state.analytics.initiativeContributorAnalyticsCount;
export const getInitiativeContributorAnalyticsLoading = (state) =>
  state.analytics.initiativeContributorAnalyticsLoading;
export const getInitiativeContributorAnalyticsError = (state) =>
  state.analytics.initiativeContributorAnalyticsError;

export const getTaskOwnerAnalytics = (state) =>
  state.analytics.taskOwnerAnalytics;
export const getTaskOwnerAnalyticsCount = (state) =>
  state.analytics.taskOwnerAnalyticsCount;
export const getTaskOwnerAnalyticsLoading = (state) =>
  state.analytics.taskOwnerAnalyticsLoading;
export const getTaskOwnerAnalyticsError = (state) =>
  state.analytics.taskOwnerAnalyticsError;

export const getTaskContributorAnalytics = (state) =>
  state.analytics.taskContributorAnalytics;
export const getTaskContributorAnalyticsCount = (state) =>
  state.analytics.taskContributorAnalyticsCount;
export const getTaskContributorAnalyticsLoading = (state) =>
  state.analytics.taskContributorAnalyticsLoading;
export const getTaskContributorAnalyticsError = (state) =>
  state.analytics.taskContributorAnalyticsError;

export default analyticsSlice.reducer;
