import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";

const initialState = {
  teams: [],
  teamsLoading: true,
  peoples: [],
  peoplesLoading: true,
  surveys: [],
  surveysLoading: true,
  rootCauses: [],
  rootCausesLoading: false,

  initiativesOnTags: null,
  initiativesOnTagsLoading: true,
  initFilterCount: 0,
  tagFilterData: [],
  ownerFilterData: [],
  selectInitTags: [],
  selectFilterTags: [],
  filterTags: [],
  filterOwners: [],
  filterActivity: "",
  filterTagsCount: 0,

  newUser: null,
  errors: {},
};

export const getTeamsAsync = createAsyncThunk(
  "teams/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/organizations/departments?page=1&perPage=100`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data?.departments;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);
export const getPeoplesAsync = createAsyncThunk(
  "peoples/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/organizations/users?page=1&perPage=100`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getOwnerPeoplesAsync = createAsyncThunk(
  "ownerPeoples/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(`${BASE_URL}/initiative/filters/owners`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getSurveysAsync = createAsyncThunk(
  "survey/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(`${BASE_URL}/surveys`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data?.data;
  }
);
export const getRootCausesAsync = createAsyncThunk(
  "rootCauses/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/surveys/${action.surveyID}/rootCauses`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data?.result;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getInitiativesOnTagsAsync = createAsyncThunk(
  "getInitiativesOnTagsAsync/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(`${BASE_URL}/tags/filter`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const addNewUserAsync = createAsyncThunk(
  "addNewUserAsync/post",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.post(
        `${BASE_URL}/create-user`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

const outerLoopSlice = createSlice({
  name: "outerloop",
  initialState,
  reducers: {
    updateFilterActivity(state, action) {
      state.filterActivity = action.payload;
    },
    updateFilterTags(state, action) {
      state.filterTags = action.payload;
    },
    updateFilterOwner(state, action) {
      state.filterOwners = action.payload;
    },
    selectFilterTags(state, action) {
      state.selectFilterTags = action.payload;
    },

    changeInitFilterCount(state, action) {
      state.initFilterCount = action.payload;
    },
    changeTagFilterData(state, action) {
      state.tagFilterData = action.payload;
    },
    changeOwnerFilterData(state, action) {
      state.ownerFilterData = action.payload;
    },
    emptyInitiativesOnTags(state) {
      state.initiativesOnTags = null;
    },
    emptyPeoples(state) {
      state.peoples = [];
      state.peoplesLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsAsync.pending, (state, action) => {
        state.teamsLoading = true;
      })
      .addCase(getTeamsAsync.fulfilled, (state, action) => {
        state.teamsLoading = false;
        state.teams = action.payload;
      })
      .addCase(getTeamsAsync.rejected, (state, action) => {
        state.teamsLoading = false;
        if (
          action.payload.statusCode === 403 ||
          action.payload.statusCode === 404
        ) {
          return;
        }
        state.errors.teams = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getPeoplesAsync.pending, (state, action) => {
        state.peoplesLoading = true;
      })
      .addCase(getPeoplesAsync.fulfilled, (state, action) => {
        state.peoplesLoading = false;
        state.peoples = action.payload.users;
      })
      .addCase(getPeoplesAsync.rejected, (state, action) => {
        state.peoplesLoading = false;
        state.errors.people = action.error?.message || "Something went wrong";
      })
      .addCase(getOwnerPeoplesAsync.pending, (state, action) => {
        state.peoplesLoading = true;
      })
      .addCase(getOwnerPeoplesAsync.fulfilled, (state, action) => {
        state.peoplesLoading = false;
        state.peoples = action.payload.result;
      })
      .addCase(getOwnerPeoplesAsync.rejected, (state, action) => {
        state.peoplesLoading = false;
      })
      .addCase(getSurveysAsync.pending, (state, action) => {
        state.surveysLoading = true;
        state.rootCauses = [];
      })
      .addCase(getSurveysAsync.fulfilled, (state, action) => {
        state.surveysLoading = false;
        state.surveys = action.payload.response;
      })
      .addCase(getSurveysAsync.rejected, (state, action) => {
        state.surveysLoading = false;
      })
      .addCase(getRootCausesAsync.pending, (state, action) => {
        state.rootCausesLoading = true;
      })
      .addCase(getRootCausesAsync.fulfilled, (state, action) => {
        state.rootCausesLoading = false;
        state.rootCauses = action.payload;
      })
      .addCase(getRootCausesAsync.rejected, (state, action) => {
        state.rootCausesLoading = false;
        if (
          action.payload.statusCode === 403 ||
          action.payload.statusCode === 404
        ) {
          return;
        }
        state.errors.rootCouse = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getInitiativesOnTagsAsync.pending, (state, action) => {
        state.initiativesOnTagsLoading = true;
      })
      .addCase(getInitiativesOnTagsAsync.fulfilled, (state, action) => {
        state.initiativesOnTags = action.payload.tags;
        state.initiativesOnTagsLoading = false;
      })
      .addCase(getInitiativesOnTagsAsync.rejected, (state, action) => {
        state.initiativesOnTagsLoading = false;
        state.errors.initiativesOnTags = "Something went wrong";
      })
      .addCase(addNewUserAsync.pending, (state, action) => {
        state.peoplesLoading = true;
      })
      .addCase(addNewUserAsync.fulfilled, (state, action) => {
        state.newUser = action.payload;
      })
      .addCase(addNewUserAsync.rejected, (state, action) => {
        state.peoplesLoading = false;
      });
  },
});

export const {
  updateFilterActivity,
  updateFilterTags,
  updateFilterOwner,
  selectFilterTags,
  changeInitFilterCount,
  changeTagFilterData,
  changeOwnerFilterData,
  emptyInitiativesOnTags,
  emptyPeoples,
} = outerLoopSlice.actions;

//Selectors

export const getTeams = (state) => state.outerLoop.teams;
export const getTeamsLoading = (state) => state.outerLoop.teamsLoading;

export const getPeoples = (state) => state.outerLoop.peoples;
export const getPeoplesLoading = (state) => state.outerLoop.peoplesLoading;

export const getSurveys = (state) => state.outerLoop.surveys;
export const getSurveysLoading = (state) => state.outerLoop.surveysLoading;

export const getRootCauses = (state) => state.outerLoop.rootCauses;
export const getRootCausesLoading = (state) =>
  state.outerLoop.rootCausesLoading;

export const getFilterActivity = (state) => state.outerLoop.filterActivity;

export const getFilterTags = (state) => state.outerLoop.filterTags;

export const getFilterOwners = (state) => state.outerLoop.filterOwners;

export const getSelectFilterTags = (state) => state.outerLoop.selectFilterTags;

export const getInitFilterCount = (state) => state.outerLoop.initFilterCount;

export const getTagFilterData = (state) => state.outerLoop.tagFilterData;

export const getOwnerFilterData = (state) => state.outerLoop.ownerFilterData;

export const getInitiativesOnTags = (state) =>
  state.outerLoop.initiativesOnTags;

export const getInitiativesOnTagsLoading = (state) =>
  state.outerLoop.initiativesOnTagsLoading;

export const getNewUser = (state) => state.outerLoop.newUser;

export const getOuteloopErrors = (state) => state.outerLoop.errors;

export default outerLoopSlice.reducer;
