import { useState } from "react";
import "./InitiativeDesktopBar.scss";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from "../../../Store/slices/user";
import { ReactComponent as Home } from "../../../Assets/Icons/Home.svg";
import { ReactComponent as BackLog } from "../../../Assets/Icons/BackLog.svg";
import { ReactComponent as History } from "../../../Assets/Icons/History.svg";
import { ReactComponent as Dashboard } from "../../../Assets/Icons/Dashboard.svg";
import { ReactComponent as Admin } from "../../../Assets/Icons/Admin.svg";
import { ReactComponent as Setting } from "../../../Assets/Icons/Setting.svg";

const InitiativeDesktopBar = () => {
  const user = useSelector(getCurrentUser);
  const [topTabs] = useState([
    {
      title: "Initiatives",
      to: `/initiatives/active?lane`,
      icon: <Home width={16} height={16} className={"panel-icon"} />,
    },
    {
      title: "Backlog",
      to: `/backlogs?lane`,
      icon: <BackLog width={16} height={16} className={"panel-icon"} />,
    },
    {
      title: "Activity logs",
      to: `/logs`,
      icon: <History width={16} height={16} className={"panel-icon"} />,
    },
    {
      title: "Dashboard",
      to: `/dashboard?analytics`,
      icon: <Dashboard width={16} height={16} className={"panel-icon"} />,
    },
  ]);

  const [bottomTabs] = useState([
    {
      title: "Admin",
      to: `/admin/?system-errors`,
      icon: <Admin width={16} height={16} className={"panel-icon"} />,
    },

    {
      title: "Settings",
      to: `/settings?settings`,
      icon: <Setting width={16} height={16} className={"panel-icon"} />,
    },
  ]);

  return (
    <div className="initiative-bar-wrapper">
      <div className="initiative-bar-container">
        <div>
          {topTabs.map((tab) => (
            <NavLink
              key={tab.to}
              className={`initiative-bar-container-navLink`}
              to={tab.to}
              id={tab.title?.toLowerCase()}
            >
              <div className="initiative-bar-container-icon">{tab.icon}</div>
              <div className="initiative-bar-container-title">{tab.title}</div>
            </NavLink>
          ))}
        </div>

        <div>
          {bottomTabs.map((tab) => {
            if (tab.title === "Admin" && !user?.isAdmin) {
              return null;
            }

            return (
              <NavLink
                key={tab.to}
                className={`initiative-bar-container-navLink`}
                to={tab.to}
                id={tab.title?.toLowerCase()}
              >
                <div className="initiative-bar-container-icon">{tab.icon}</div>
                <div className="initiative-bar-container-title">{tab.title}</div>
              </NavLink>
            );
          })}
        </div>
      </div>
      <div className="initiative-bar-toolbar" />
    </div>
  );
};

export default InitiativeDesktopBar;
