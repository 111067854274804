import Cookies from "js-cookie";
import { COOKIE_DOMAIN } from "../Constants/Api";

export const setCookies = (name, value) => {
  return Cookies.set(name, value, {
    path: "/",
    domain: COOKIE_DOMAIN,
  });
};

export const getCookies = (name) => {
  return Cookies.get(name);
};

export const removeCookies = (name) => {
  return Cookies.remove(name, {
    expires: 0,
    path: "/",
    domain: COOKIE_DOMAIN,
  });
};
