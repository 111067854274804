import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { PerPageTwenty } from "../../../Constants/Glopalconstants";

const initialState = {
  activityFeeds: [],
  activityFeedsCount: 0,
  activityFeedsLoading: true,
  activityFeedsError: "",
};

export const getActivityFeedAsync = createAsyncThunk(
  "activityLogs/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/initiative/comments-logs/${action.initID}?offset=${action.offset}&limit=${PerPageTwenty}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return { response: response.data };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const createCommentsAsync = createAsyncThunk(
  "comments/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/initiative-comments`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

const activityFeedSlice = createSlice({
  name: "activityFeed",
  initialState,
  reducers: {
    resetActivityFeed: () => initialState,
    addNewComment(state, action) {
      state.activityFeeds = [action.payload, ...state.activityFeeds];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivityFeedAsync.pending, (state, action) => {
      state.activityFeedsLoading = true;
    });
    builder.addCase(getActivityFeedAsync.fulfilled, (state, action) => {
      state.activityFeeds = [
        ...state.activityFeeds,
        ...action.payload.response.result,
      ];
      state.activityFeedsCount = action.payload.response.counts;
      state.activityFeedsLoading = false;
    });
    builder.addCase(getActivityFeedAsync.rejected, (state, action) => {
      state.activityFeedsLoading = false;
      state.activityFeedsError = "Something went wrong";
    });
  },
});

export const { resetActivityFeed, addNewComment } = activityFeedSlice.actions;

//Selectors
export const getActivityFeeds = (state) => state.activityFeed.activityFeeds;
export const getActivityFeedsCount = (state) =>
  state.activityFeed.activityFeedsCount;
export const getActivityFeedsLoading = (state) =>
  state.activityFeed.activityFeedsLoading;
export const getActivityFeedsError = (state) =>
  state.activityFeed.activityFeedsError;

export default activityFeedSlice.reducer;
