import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { strongCopyData } from "../../../Utils";
import { PerPageThen } from "../../../Constants/Glopalconstants";

const initialState = {
  initiativesCounts: null,
  initiativesCountsLoading: true,

  initiativesColumns: [],
  initiativesColumnsLoading: false,
  initiativesColumnsScrollLoading: false,

  initiativesTable: {},
  initiativesTableLoading: false,
  initiativesTableLaneIndex: 0,

  initCoordinateAxis: [],
  selectInitCoordinateAxis: null,
  initCoordinateAxisLoading: false,

  initImpactGroup: [],
  initImpactGroupCount: 0,
  initImpactGroupLoading: false,

  initCheckpoints: {},
  initCheckpointMonths: null,
  initCheckpointLoading: false,
  initCheckpointsPage: 0,

  initWins: [],
  allInitWinsCount: [],
  initWinsLoading: null,

  initDeleted: [],
  initDeletedCount: 1,
  initDeletedLoading: false,

  errors: {},
};

let controller;

export const getInitiativesCountsAsync = createAsyncThunk(
  "initiativesCount/get",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.get(`${BASE_URL}/initiative/counts`, {
        headers: {
          "x-feedback": feedbackId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getInitiativesFilterCountsAsync = createAsyncThunk(
  "initiativesFilterCount/post",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.post(
        `${BASE_URL}/initiative/filter/counts`,
        {
          ...action,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getInitiativeFilterAsync = createAsyncThunk(
  "getInitiative/post",
  async (action, state) => {
    controller = new AbortController();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const initiatives = await Promise.all(
      action.lanes.map((lane) => {
        return axios.post(
          `${BASE_URL}/initiative/feedback/filter?page=${action.page}&perPage=${action.perPage}&type=${action.type}`,
          {
            status: action.status,
            tags: action.tags,
            owners: action.owners,
            lane: lane,
            warningStatus: "all",
          },
          {
            signal: controller.signal,
            headers: {
              "x-feedback": feedbackId,
            },
          }
        );
      })
    );
    return initiatives?.map((initiative) => initiative.data);
  }
);

export const getInitiativeScrollFilterAsync = createAsyncThunk(
  "getInitiativeScroll/post",
  async (action, state) => {
    controller = new AbortController();
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/initiative/feedback/filter?page=${action.page}&perPage=${action.perPage}&type=${action.type}`,
      {
        status: action.status,
        tags: action.tags,
        lane: action.lane,
        warningStatus: "all",
      },
      {
        signal: controller.signal,
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return {
      initiatives: response.data.initiatives,
      allCount: response.data.allCount,
      lane: action.lane,
      page: response.data.page,
      paginate: response.data.paginate,
      isDelete: action.isDelete,
    };
  }
);

export const getInitiativeTableFilterAsync = createAsyncThunk(
  "getInitiativeTable/post",
  async (action, state) => {
    controller = new AbortController();
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/initiative/feedback/filter?page=${action.page}&perPage=${action.perPage}&type=${action.type}`,
      {
        status: action.status,
        lane: action.lane,
        tags: action.tags,
        owners: action.owners,
        warningStatus: action.warningStatus || "all",
      },
      {
        signal: controller.signal,
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return {
      initiatives: response.data.initiatives,
      allCount: response.data.allCount,
      page: response.data.page,
      lane: action.lane,
      status: action.status,
    };
  }
);

export const getInitCheckpointFilterAsync = createAsyncThunk(
  "checkpoint/post",
  async (action, state) => {
    controller = new AbortController();
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/checkpoint/status`,
      {
        status: action.status,
        lane: action.lane,
        tags: action.tags,
        owners: action.owners,
      },
      {
        signal: controller.signal,
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return {
      initiatives: response.data.data || [],
      months: response.data.months || [],
      page: response.data.page,
      lane: response.data.lane,
      status: action.status,
    };
  }
);

export const getInitCoordinateAxisAsync = createAsyncThunk(
  "CoordinateAxis/post",
  async (action, state) => {
    controller = new AbortController();
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/initiative/coordinate-axis`,
      {
        tags: action?.tags || [],
        owners: action.owners,
        status: action?.status || "active",
      },
      {
        signal: controller.signal,
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getWinsAsync = createAsyncThunk(
  "getWinsAsync/post",
  async (action, state) => {
    try {
      controller = new AbortController();
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.post(
        `${BASE_URL}/initiative/solutions?page=${action.page}&perPage=${PerPageThen}`,
        {
          status: action.status,
          tags: action.tags,
          owners: action.owners,
        },
        {
          signal: controller.signal,
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getInitDeletedAsync = createAsyncThunk(
  "getInitDeleted/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/initiative/recycle-bin?page=${action.page}&perPage=${action.perPage}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteAllDeletedInitiativesAsync = createAsyncThunk(
  "deleteAllDeletedInitiativesAsync/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(
      `${BASE_URL}/initiative/empty-recycle-bin`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitImpactGroupAsync = createAsyncThunk(
  "initImpactGroup/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/impact-widget/feedback?page=${action.page}&perPage=${action.perPage}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createInitImpactGroupAsync = createAsyncThunk(
  "initImpactGroup/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(`${BASE_URL}/impact-widget`, action, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateInitImpactGroupAsync = createAsyncThunk(
  "initImpactGroup/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/impact-widget/${action.widgetId}`,
      action,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteInitImpactGroupAsync = createAsyncThunk(
  "initImpactGroup/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(
      `${BASE_URL}/impact-widget/${action.widgetId}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const initiativesSlice = createSlice({
  name: "initiatives",
  initialState,
  reducers: {
    resetInitiatives: () => initialState,

    emptyInitiativesLanes(state) {
      state.initiativesColumns = [];
      state.initiativesColumnsLoading = false;
      state.initiativesColumnsScrollLoading = false;
    },

    emptyInitiativesTables(state) {
      state.initiativesTable = {};
      state.initiativesTableLoading = false;
      state.initiativesTableLaneIndex = 0;
    },

    emptyInitiativesAxis(state) {
      state.initImpactGroup = [];
      state.initImpactGroupCount = 0;
      state.initImpactGroupLoading = false;
      state.initCoordinateAxis = [];
      state.selectInitCoordinateAxis = null;
      state.initCoordinateAxisLoading = false;
    },

    emptyInitiativesCheckpoints(state) {
      state.initCheckpoints = {};
      state.initCheckpointMonths = null;
      state.initCheckpointLoading = false;
      state.initCheckpointsPage = 0;
    },

    emptyInitiativesWins(state) {
      state.initWins = [];
      state.allInitWinsCount = [];
      state.initWinsLoading = null;
    },

    emptyInitiativesDeleted(state) {
      state.initDeleted = [];
      state.initDeletedCount = 1;
      state.initDeletedLoading = false;
    },

    deleteOrRestoreCurrentInitiative(state, action) {
      if (action.payload.status === "deleted") {
        state.initDeleted = state.initDeleted.filter(
          (init) => init?.id !== action?.payload?.id
        );
        state.initDeletedCount += -1;
      } else {
        const lane = state.initiativesColumns.find(
          (el) => el.lane === action.payload.key
        );
        lane.initiatives = lane?.initiatives.filter(
          (init) => init?.id !== action?.payload?.id
        );
      }
      state.initiativesCounts[action.payload.lane] += action.payload.count;
      state.initiativesCounts.deletedCount += action.payload.deleteCount;
    },

    selectCurrentInitCoordinateAxis(state, action) {
      if (
        action?.payload?.combination ===
        state?.selectInitCoordinateAxis?.combination
      ) {
        state.selectInitCoordinateAxis = null;
      } else {
        state.selectInitCoordinateAxis = action.payload;
      }
    },

    sortInitTable: (state, action) => {
      state.initiativesTable = action.payload;
    },
    sortInitWins(state, action) {
      state.initWins = action.payload;
    },
    incrementInitiativesTableLaneIndex(state) {
      state.initiativesTableLaneIndex += 1;
    },
    incrementInitCheckpointsPage(state) {
      state.initCheckpointsPage += 1;
    },
    changeInitiativesColumnData(state, action) {
      const { initiativeId, lane, oldLane } = action.payload;
      const dragLane = state.initiativesColumns.find(
        (el) => el.lane === oldLane
      );
      const initiative = dragLane?.initiatives?.find(
        (drIn) => drIn.id === initiativeId
      );
      const dropLane = state.initiativesColumns?.find((el) => el.lane === lane);

      dropLane.initiatives = [initiative, ...dropLane.initiatives];
      dragLane.initiatives = dragLane?.initiatives?.filter(
        (init) => init.id !== initiativeId
      );
    },
    abortRequest() {
      if (controller) {
        controller.abort();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitiativesCountsAsync.pending, (state, action) => {
        state.initiativesCountsLoading = true;
        state.errors.initCount = null;
      })
      .addCase(getInitiativesCountsAsync.fulfilled, (state, action) => {
        state.initiativesCountsLoading = false;
        state.initiativesCounts = action.payload;
      })
      .addCase(getInitiativesCountsAsync.rejected, (state, action) => {
        state.initiativesCountsLoading = false;
        state.errors.initCount = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getInitiativesFilterCountsAsync.pending, (state, action) => {
        state.initiativesCountsLoading = true;
        state.errors.initCount = null;
      })
      .addCase(getInitiativesFilterCountsAsync.fulfilled, (state, action) => {
        state.initiativesCountsLoading = false;
        state.initiativesCounts = action.payload;
      })
      .addCase(getInitiativesFilterCountsAsync.rejected, (state, action) => {
        state.initiativesCountsLoading = false;
        state.errors.initCount = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })

      .addCase(getInitiativeFilterAsync.pending, (state, action) => {
        state.initiativesColumnsLoading = true;
      })
      .addCase(getInitiativeFilterAsync.fulfilled, (state, action) => {
        state.initiativesColumns = action.payload;
        state.initiativesColumnsLoading = false;
      })
      .addCase(getInitiativeFilterAsync.rejected, (state, action) => {
        state.initiativesColumnsLoading = false;
      })
      .addCase(getInitiativeScrollFilterAsync.pending, (state, action) => {
        state.initiativesColumnsScrollLoading = true;
      })
      .addCase(getInitiativeScrollFilterAsync.fulfilled, (state, action) => {
        const index = state.initiativesColumns.findIndex(
          (item) => item.lane === action.payload.lane
        );
        if (!action.payload.isDelete) {
          state.initiativesColumns[index].initiatives = [
            ...state.initiativesColumns[index].initiatives,
            ...action.payload.initiatives,
          ];
        } else {
          state.initiativesColumns[index].initiatives = [
            ...action.payload.initiatives,
          ];
        }
        state.initiativesColumns[index].page = action.payload.page;
        state.initiativesColumns[index].paginate = action.payload.paginate;
        state.initiativesColumns[index].allCount = action.payload.allCount;
        state.initiativesColumnsScrollLoading = false;
      })
      .addCase(getInitiativeScrollFilterAsync.rejected, (state, action) => {
        state.initiativesColumnsScrollLoading = false;
      })

      .addCase(getInitiativeTableFilterAsync.pending, (state, action) => {
        state.initiativesTableLoading = true;
        state.errors.initiativesTable = null;
      })
      .addCase(getInitiativeTableFilterAsync.fulfilled, (state, action) => {
        const oldData = state.initiativesTable[action.payload.status] || [];
        const key = action.payload.status;
        state.initiativesTable[key] = [...oldData, action.payload.initiatives];
        state.initiativesTableLoading = false;
      })
      .addCase(getInitiativeTableFilterAsync.rejected, (state, action) => {
        if (action.error?.message !== "canceled") {
          state.initiativesTableLoading = false;
          state.errors.initiativesTable = true;
        }
      })
      .addCase(getInitCheckpointFilterAsync.pending, (state, action) => {
        state.initCheckpointLoading = true;
      })
      .addCase(getInitCheckpointFilterAsync.fulfilled, (state, action) => {
        const { months, status, initiatives } = action.payload;
        const oldData = state.initCheckpoints[status] || [];
        state.initCheckpoints[status] = [...oldData, initiatives];

        if (!state.initCheckpointMonths) {
          state.initCheckpointMonths = months;
        }
        state.initCheckpointLoading = false;
      })
      .addCase(getInitCheckpointFilterAsync.rejected, (state, action) => {
        state.initCheckpointLoading = false;
      })
      .addCase(getInitCoordinateAxisAsync.pending, (state, action) => {
        state.initCoordinateAxisLoading = true;
      })
      .addCase(getInitCoordinateAxisAsync.fulfilled, (state, action) => {
        state.initCoordinateAxisLoading = false;
        state.initCoordinateAxis = action.payload.combinations;
      })
      .addCase(getInitCoordinateAxisAsync.rejected, (state, action) => {
        state.initCoordinateAxisLoading = false;
      })
      .addCase(getWinsAsync.pending, (state, action) => {
        state.initWinsLoading = true;
        state.errors.initiative = null;
      })
      .addCase(getWinsAsync.fulfilled, (state, action) => {
        state.initWinsLoading = false;
        state.initWins = [...state.initWins, ...action.payload.initiatives];
        state.allInitWinsCount = action.payload.count;
      })
      .addCase(getWinsAsync.rejected, (state, action) => {
        state.initWinsLoading = false;
        state.errors.initiative = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getInitDeletedAsync.pending, (state, action) => {
        state.initDeletedLoading = true;
      })
      .addCase(getInitDeletedAsync.fulfilled, (state, action) => {
        state.initDeleted = action.payload.initiatives;
        state.initDeletedCount = action.payload.count;
        state.initDeletedLoading = false;
      })
      .addCase(getInitDeletedAsync.rejected, (state, action) => {
        state.initDeletedLoading = false;
      })

      .addCase(deleteAllDeletedInitiativesAsync.pending, (state, action) => {
        state.initDeletedLoading = true;
      })
      .addCase(deleteAllDeletedInitiativesAsync.fulfilled, (state, action) => {
        state.initDeletedLoading = false;
        state.initDeleted = [];
        state.initiativesCounts.deletedCount = 0;
      })
      .addCase(deleteAllDeletedInitiativesAsync.rejected, (state, action) => {
        state.initDeletedLoading = false;
      })
      .addCase(getInitImpactGroupAsync.pending, (state, action) => {
        state.initImpactGroupLoading = true;
        state.errors.initImpactGroup = null;
      })
      .addCase(getInitImpactGroupAsync.fulfilled, (state, action) => {
        state.initImpactGroup = action.payload.widgetsData;
        state.initImpactGroupCount = action.payload.count;
        state.initImpactGroupLoading = false;
      })
      .addCase(getInitImpactGroupAsync.rejected, (state, action) => {
        state.errors.initImpactGroup = "Something went wrong";
        state.initImpactGroupLoading = false;
      })
      .addCase(createInitImpactGroupAsync.fulfilled, (state, action) => {
        state.initImpactGroup = [action.payload, ...state.initImpactGroup];
        if (state.initImpactGroup.length > 5) {
          state.initImpactGroup.pop();
        }
        state.initImpactGroupCount += 1;
      })
      .addCase(updateInitImpactGroupAsync.fulfilled, (state, action) => {
        const index = state.initImpactGroup.findIndex(
          (item) => item.id === action.payload.id
        );
        state.initImpactGroup[index] = action.payload;
      })
      .addCase(deleteInitImpactGroupAsync.fulfilled, (state, action) => {
        state.initImpactGroup = strongCopyData(state.initImpactGroup).filter(
          (impactGroup) => impactGroup.id !== action.payload?.id
        );
        state.initImpactGroupCount -= 1;
      });
  },
});

export const {
  resetInitiatives,
  emptyInitiativesLanes,
  emptyInitiativesTables,
  emptyInitiativesAxis,
  emptyInitiativesCheckpoints,
  emptyInitiativesWins,
  emptyInitiativesDeleted,
  deleteOrRestoreCurrentInitiative,
  selectCurrentInitCoordinateAxis,
  sortInitTable,
  sortInitWins,
  incrementInitiativesTableLaneIndex,
  incrementInitCheckpointsPage,
  changeInitiativesColumnData,
  abortRequest,
} = initiativesSlice.actions;

//Selectors
export const getInitiativesColumns = (state) =>
  state.initiatives.initiativesColumns;

export const getInitiativesColumnsLoading = (state) =>
  state.initiatives.initiativesColumnsLoading;

export const getInitiativesColumnsScrollLoading = (state) =>
  state.initiatives.initiativesColumnsScrollLoading;

export const getInitiativesCounts = (state) =>
  state.initiatives.initiativesCounts;

export const getInitiativesCountsLoading = (state) =>
  state.initiatives.initiativesCountsLoading;

export const getInitiativesTable = (state) =>
  state.initiatives.initiativesTable;

export const getInitiativesTableLoading = (state) =>
  state.initiatives.initiativesTableLoading;

export const getInitiativesTableLaneIndex = (state) =>
  state.initiatives.initiativesTableLaneIndex;

export const getInitCheckpoints = (state) => state.initiatives.initCheckpoints;

export const getInitCheckpointMonths = (state) =>
  state.initiatives.initCheckpointMonths;

export const getInitCheckpointLoading = (state) =>
  state.initiatives.initCheckpointLoading;

export const getInitCheckpointsPage = (state) =>
  state.initiatives.initCheckpointsPage;

export const getInitCoordinateAxis = (state) =>
  state.initiatives.initCoordinateAxis;

export const getSelectInitCoordinateAxis = (state) =>
  state.initiatives.selectInitCoordinateAxis;

export const getInitCoordinateAxisLoading = (state) =>
  state.initiatives.initCoordinateAxisLoading;

export const getInitWins = (state) => state.initiatives.initWins;

export const getInitWinsCount = (state) => state.initiatives.allInitWinsCount;

export const getInitWinsLoading = (state) => state.initiatives.initWinsLoading;

export const getInitDeleted = (state) => state.initiatives.initDeleted;

export const getInitDeletedCount = (state) =>
  state.initiatives.initDeletedCount;

export const getInitDeletedLoading = (state) =>
  state.initiatives.initDeletedLoading;

export const getInitImpactGroup = (state) => state.initiatives.initImpactGroup;
export const getInitImpactGroupCount = (state) =>
  state.initiatives.initImpactGroupCount;
export const getInitImpactGroupLoading = (state) =>
  state.initiatives.initImpactGroupLoading;

export const getInitiativeErrors = (state) => state.initiatives.errors;

export default initiativesSlice.reducer;
