/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TOKEN_NAME, TOKEN_SERVER_URL } from '../../Constants/Api';
import { setCookies } from "../../Utils/cookies";

export function Auth() {
  let query = useQuery();
  const navigate = useNavigate();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const code = query.get("code");
  useEffect(() => {
    axios
      .get(`${TOKEN_SERVER_URL}/?code=${code}`)
      .then((res) => {
        if (res.data.access_token) {
          setCookies(TOKEN_NAME, res.data.access_token);
        }
      })
      .then(() => {
        navigate("/feedbacks");
      });
  }, []);

  return <div></div>;
}
