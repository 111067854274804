import React, { useState } from "react";
import "./Pagination.scss";
import { ReactComponent as RightArrow } from "../../../Assets/Icons/RightArrow.svg";
import { ReactComponent as LeftArrow } from "../../../Assets/Icons/LeftArrow.svg";

const Pagination = ({
  currentPage = 1,
  perPage = 5,
  allCount = 6,
  loading = false,
  changePage,
}) => {
  const [open, setOpen] = useState(false);
  const huddleClick = (page) => {
    setOpen(!open);
    changePage(page);
  };

  const huddleClickArrow = (index) => {
    const page = currentPage + index;
    if (page > 0 && page <= Math.ceil(allCount / perPage)) {
      changePage(page);
    }
  };
  return (
    <div
      className={
        loading
          ? "pagination-container  pagination-loading"
          : "pagination-container"
      }
    >
      <div
        className={
          open
            ? "pagination-container-popover open-popover"
            : "pagination-container-popover "
        }
      >
        {new Array(Math.floor(allCount / perPage)).fill("").map((_, index) => (
          <button
            title="allCount"
            key={index}
            className="pagination-container-popover-button"
            onClick={() => huddleClick(index + 1)}
          >
            {`${(index + 1 - 1) * perPage + 1} - ${
              (index + 1) * perPage
            }  of ${allCount}`}
          </button>
        ))}
        {Math.abs(allCount % perPage) ? (
          <button
            title="allCount"
            className="pagination-container-popover-button"
            onClick={() => huddleClick(Math.ceil(allCount / perPage))}
          >
            {`${
              allCount - Math.abs(allCount % perPage) + 1
            } - ${allCount}  of ${allCount}`}
          </button>
        ) : null}
      </div>
      <div className="pagination-container-button-container">
        <button
          aria-label="LeftArrow"
          className="pagination-container-button-arrow"
          onClick={() => huddleClickArrow(-1)}
        >
          {<LeftArrow />}
        </button>
        <button
          aria-label="Pages"
          className={
            open
              ? "pagination-container-button open-button"
              : "pagination-container-button"
          }
          onClick={() => setOpen(!open)}
        >
          {`${(currentPage - 1) * perPage + 1} - ${
            currentPage * perPage < allCount ? currentPage * perPage : allCount
          }  of ${allCount}`}
        </button>
        <button
          aria-label="RightArrow"
          className="pagination-container-button-arrow"
          onClick={() => huddleClickArrow(1)}
        >
          {<RightArrow />}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
