import "./InitiativeMobileBar.scss";
import { useState } from "react";
import { getClassNameByParam } from "../../../Utils";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../Store/slices/user";
import { ReactComponent as Home } from "../../../Assets/Icons/Home.svg";
import { ReactComponent as BackLog } from "../../../Assets/Icons/BackLog.svg";
import { ReactComponent as History } from "../../../Assets/Icons/History.svg";
import { ReactComponent as Dashboard } from "../../../Assets/Icons/Dashboard.svg";
import { ReactComponent as Admin } from "../../../Assets/Icons/Admin.svg";
import { ReactComponent as Setting } from "../../../Assets/Icons/Setting.svg";

const InitiativeMobileBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector(getCurrentUser);

  const [topTabs] = useState([
    {
      title: "Initiatives",
      to: `/initiatives/active?lane`,
      icon: <Home width={16} height={16} className={"panel-icon"} />,
    },
    {
      title: "Backlog",
      to: `/backlogs?lane`,
      icon: <BackLog width={16} height={16} className={"panel-icon"} />,
    },
    {
      title: "logs",
      to: `/logs`,
      icon: <History width={16} height={16} className={"panel-icon"} />,
    },
    {
      title: "Dashboard",
      to: `/dashboard?analytics`,
      icon: <Dashboard width={16} height={16} className={"panel-icon"} />,
    },
  ]);

  const [bottomTabs] = useState([
    {
      title: "Admin",
      to: `/admin/?system-errors`,
      icon: <Admin width={16} height={16} className={"panel-icon"} />,
    },

    {
      title: "Settings",
      to: `/settings?settings`,
      icon: <Setting width={16} height={16} className={"panel-icon"} />,
    },
  ]);

  const onOutsideClick = (e) => {
    if (e.target.attributes.id) {
      setIsMenuOpen(false);
    }
  };

  return (
    <div
      id={"home"}
      onClick={onOutsideClick}
      className={`initiative-mobile-bar ${getClassNameByParam(
        isMenuOpen,
        "initiative-mobile-bar--open"
      )}`}
    >
      <div
        className={`${getClassNameByParam(
          isMenuOpen,
          "initiative-mobile-bar--header-open"
        )}`}
      >
        <div
          className={`menu-btn ${getClassNameByParam(isMenuOpen, "open")}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="menu-btn__burger"></div>
        </div>
      </div>

      <div
        className={`initiative-mobile-bar--items ${getClassNameByParam(
          isMenuOpen,
          "initiative-mobile-bar--items-open"
        )}`}
      >
        <div>
          {topTabs.map((tab) => (
            <NavLink
              key={tab.to}
              className={`initiative-bar-container-navLink`}
              to={tab.to}
              id={tab.title?.toLowerCase()}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="initiative-bar-container-icon">{tab.icon}</div>
              <div className="initiative-bar-container-title">{tab.title}</div>
            </NavLink>
          ))}
        </div>

        <div>
          {bottomTabs.map((tab) => {
            if (tab.title === "Admin" && !user?.isAdmin) {
              return null;
            }

            return (
              <NavLink
                key={tab.to}
                className={`initiative-bar-container-navLink`}
                to={tab.to}
                id={tab.title?.toLowerCase()}
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="initiative-bar-container-icon">{tab.icon}</div>
                <div className="initiative-bar-container-title">
                  {tab.title}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InitiativeMobileBar;
