import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectUser } from "../../../Store/slices/user";
import Avatar from "../Avatar/Avatar";

const FilterLogButton = ({ navigateOption, stateOption, name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onHandleClick = () => {
    dispatch(setSelectUser(null));
    navigate(navigateOption, stateOption);
  };
  return (
    <button className={"log-btn"} onClick={onHandleClick}>
      {name}
    </button>
  );
};

const UserLogButton = ({ onClick, user }) => {
  return (
    <div className={"user-log-btn-block"}>
      <Avatar people={user} />
      <button className={"user-log-btn"} onClick={onClick}>
        {`${user?.username || user?.emailAddress}`}
      </button>
    </div>
  );
};

const filterLogHistory = ({ log, openUserLogHistory }) => {
  const getTaskHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created task `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE_OWNER":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated task `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.name}"`}
            />
            {` owner`}
          </>
        );
        break;
      case "UPDATE_STATUS":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated task `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.name}"`}
            />
            {` status from ${log.to?.status} to ${log.from.status}`}
          </>
        );
        break;
      case "UPDATE_DESCRIPTION":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated task `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.name}"`}
            />
            {` description from "${log.from.description}" to "${log.to?.description}"`}
          </>
        );
        break;
      case "UPDATE_NAME":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated task name "${log.from.name}" to `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE_DUE_DATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated task `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.name}"`}
            />
            {` due date from "${new Date(log.from.dueDate)
              .toISOString()
              .slice(0, 10)}" to "${new Date(log.to.dueDate)
              .toISOString()
              .slice(0, 10)}"`}
          </>
        );
        break;
      case "ADD_PEOPLE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated task `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.from.name}"`}
            />
            {` contributors`}
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted task name "${log.to?.name}"`}
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getHuddleHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created huddle in `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/huddles`,
              }}
              name={`"${log.to?.initiative.name}"`}
            />
            {` initiative`}
          </>
        );
        break;
      case "REMOVE_PEOPLE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated people of a huddle in the  `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/huddles`,
              }}
              name={`"${log.to?.initiative.name}"`}
            />
            {` initiative`}
          </>
        );
        break;
      case "ADD_TEAMS":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` added teams of a huddle in the  `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/huddles`,
              }}
              name={`"${log.to?.initiative.name}"`}
            />
            {` initiative`}
          </>
        );
        break;
      case "ADD_PEOPLE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` added people of a huddle in the  `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/huddles`,
              }}
              name={`"${log.to?.initiative.name}"`}
            />
            {` initiative`}
          </>
        );
        break;
      case "REMOVE_TEAMS":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated teams of a huddle in the  `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/huddles`,
              }}
              name={`"${log.to?.initiative.name}"`}
            />
            {` initiative`}
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted a huddle in the "${log.to?.initiative.name}"`}
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getCheckpointHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created checkpoint `}
            <FilterLogButton
              navigateOption={{
                pathname: `/settings`,
                search: "settings",
              }}
              name={`"${log.to?.checkpoint}"`}
            />
          </>
        );
        break;
      case "UPDATE_NAME":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated checkpoint from "${log.from.checkpoint}" to `}
            <FilterLogButton
              navigateOption={{
                pathname: `/settings`,
                search: "settings",
              }}
              name={`"${log.to?.checkpoint}"`}
            />
          </>
        );
        break;
      case "UPDATE_PRIORITY":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` reordered checkpoints`}
          </>
        );
        break;
      case "CHECK_CHECKPOINT":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` checked `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.checkpoint}"`}
            />
            {" checkpoint in initiative "}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/tasks`,
              }}
              name={`"${log.to?.initiative.name}"`}
            />
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted a checkpoint "${log.to?.checkpoint}"`}
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getLaneHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created lane `}
            <FilterLogButton
              navigateOption={{
                pathname: `/settings`,
                search: "settings",
              }}
              name={`"${log.to?.lane}"`}
            />
          </>
        );
        break;
      case "UPDATE_NAME":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated lane from "${log.from.lane}" to `}
            <FilterLogButton
              navigateOption={{
                pathname: `/settings`,
                search: "settings",
              }}
              name={`"${log.to?.lane}"`}
            />
          </>
        );
        break;
      case "UPDATE_PRIORITY":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` reordered lanes`}
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted a lane "${log.to?.lane}"`}
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getBacklogHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created backlog `}
            <FilterLogButton
              navigateOption={{
                pathname: `/backlogs/backlog`,
                search: "update-backlog",
                hash: log.to?.id,
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.title}"`}
            />
          </>
        );
        break;
      case "UPDATE_TITLE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated backlog title from "${log.from.title}" to `}
            <FilterLogButton
              navigateOption={{
                pathname: `/backlogs/backlog`,
                search: "update-backlog",
                hash: log.to?.id,
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.title}"`}
            />
          </>
        );
        break;
      case "UPDATE_DESCRIPTION":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated backlog description`}
          </>
        );
        break;
      case "TURNED_TO_INITIATIVE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` turned  backlog "${log.from.title}" into an initiative`}
          </>
        );
        break;
      case "LIKE_BACKLOG":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` liked backlog `}
            <FilterLogButton
              navigateOption={{
                pathname: `/backlogs/backlog`,
                search: "update-backlog",
                hash: log.to?.id,
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.title}"`}
            />
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted a backlog "${log.to?.title}"`}
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getImpactEffortGroupHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created impact effort group `}
            <FilterLogButton
              navigateOption={{
                pathname: `/effort-impact-tags`,
                search: "update",
                hash: log.to?.id,
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE_TAGS_IN_GROUP":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated impact effort group`}
          </>
        );
        break;
      case "UPDATE_NAME":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated impact effort group from "${log.from.name}" to `}
            <FilterLogButton
              navigateOption={{
                pathname: `/effort-impact-tags`,
                search: "update",
                hash: log.to?.id,
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted a impact effort group "${log.to?.name}"`}
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getInitiativeHistory = () => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE_NAME":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated initiative from "${log.from.name}" to `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE_DESCRIPTION":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated initiative`}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              name={`"${log.to?.name}"`}
            />
            {" description "}
          </>
        );
        break;
      case "UPDATE_STATUS":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
            {` status from "${log.from.status}"  to "${log.to?.status}"`}
          </>
        );
        break;
      case "UPDATE_HEALTH_STATUS":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
            {` status from "${log.from.healthStatus}"  to "${log.to?.healthStatus}"`}
          </>
        );
        break;
      case "UPDATE_OWNER":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
            {` owner`}
          </>
        );
        break;
      case "UPDATE_EFFORT_LEVEL":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
            {` owner`}
          </>
        );
        break;
      case "UPDATE_IMPACT_LEVEL":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
            {` owner`}
          </>
        );
        break;
      case "MOVED_TO_RECYCLE_BIN":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` moved initiative "${log.from.name}" to recycle bin`}
          </>
        );
        break;
      case "RESTORED_FROM_RECYCLE_BIN":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` restored initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.status}/${log.to?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
            {` from recycle bin`}
          </>
        );
        break;
      case "EMPTY_RECYCLE_BIN":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` emptied recycle bin`}
          </>
        );
        break;
      case "ADD_PEOPLE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` set ${log?.to?.people} as ${log?.to?.type} in `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "REMOVE_PEOPLE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` removed ${log?.to?.people} from ${log?.to?.type} in `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/huddles`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log?.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "ADD_ROOT_CAUSE_NOTE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` added note in fishbone RCA "${log.to?.type}" in initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/fishbone-rca`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log?.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE_ROOT_CAUSE_NOTE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated note in fishbone RCA "${log.to?.type}" in initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/fishbone-rca`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log?.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "REMOVE_ROOT_CAUSE_NOTE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted note in fishbone RCA "${log.from?.type}" in initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.from?.initiative?.status}/${log.from?.initiative?.id}/fishbone-rca`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log?.from?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "ADD_ROOT_CAUSE_USER":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` added user in fishbone RCA "${log.to?.type}" in initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/fishbone-rca`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log?.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "REMOVE_ROOT_CAUSE_USER":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted user in fishbone RCA "${log.from?.type}" in initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/fishbone-rca`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log?.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "ADD_ROOT_CAUSE_COMMENT":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` commented on the note in fishbone RCA in initiative `}
            <FilterLogButton
              navigateOption={`/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/fishbone-rca`}
              stateOption={{ state: { backlog: true } }}
              name={`"${log?.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getTagsHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` created tags group `}
            <FilterLogButton
              navigateOption={{
                pathname: `/settings`,
                search: "tags",
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE_NAME":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` updated tags group name from `}
            <FilterLogButton
              navigateOption={{
                pathname: `/settings`,
                search: "tags",
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.from.name}"`}
            />
            {" to "}
            <FilterLogButton
              navigateOption={{
                pathname: `/settings`,
                search: "tags",
              }}
              stateOption={{ state: { backlog: true } }}
              name={`"${log.to?.name}"`}
            />
          </>
        );
        break;
      case "ADD_TAGS_IN_GROUP":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {log.from.tags.length ? (
              <>
                {` updated initiative "${log.to?.name}" tags from "`}
                {log.from.tags?.map((tag) => `${tag.name}, `)}
                {`" to `}
              </>
            ) : (
              <>{` edited initiative "${log.to?.name}" tags "`}</>
            )}
            {log.to?.tags?.map((tag) => `${tag.name}, `)}
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}
            {` deleted tags group "${log.to?.name}"`}
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const getMilestoneHistory = (log) => {
    const options = { year: "numeric", month: "long" };
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}

            {` added milestone(s) for `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/milestones`,
              }}
              name={`"${new Date(log?.createdAt)?.toLocaleDateString(
                "en-US",
                options
              )}"`}
            />
            {` in `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/milestones`,
              }}
              name={`"${log.to?.initiative?.name}"`}
            />
            {` initiative`}
          </>
        );
        break;
      case "UPDATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}

            {` updated milestone(s) for `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/milestones`,
              }}
              name={`"${new Date(log?.createdAt)?.toLocaleDateString(
                "en-US",
                options
              )}"`}
            />
            {` in `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative.status}/${log.to?.initiative.id}/milestones`,
              }}
              name={`"${log.to?.initiative?.name}"`}
            />
            {` initiative`}
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            <UserLogButton
              onClick={() => openUserLogHistory(log?.doneBy)}
              user={log?.user}
            />
            {` deleted "${new Date(log?.createdAt)?.toLocaleDateString(
              "en-US",
              options
            )}" milestone in "${log.to?.initiative?.name}" initiative`}
          </>
        );
        break;

      default:
        break;
    }
    return history;
  };

  const getMetricsHistory = (log) => {
    let history = "";
    switch (log.action) {
      case "CREATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}

            {` added metrics "${log.to.name}" in initiative `}

            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/kpis`,
              }}
              name={`"${log.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "UPDATE":
        history = (
          <>
            {openUserLogHistory && (
              <UserLogButton
                onClick={() => openUserLogHistory(log?.doneBy)}
                user={log?.user}
              />
            )}

            {` updated metrics from "${log.from?.name}" to "${log.to.name}" in initiative `}

            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/kpis`,
              }}
              name={`"${log.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      case "DELETE":
        history = (
          <>
            <UserLogButton
              onClick={() => openUserLogHistory(log?.doneBy)}
              user={log?.user}
            />
            {` deleted metrics "${log.to.name}" in initiative `}
            <FilterLogButton
              navigateOption={{
                pathname: `/initiative/${log.to?.initiative?.status}/${log.to?.initiative?.id}/kpis`,
              }}
              name={`"${log.to?.initiative?.name}"`}
            />
          </>
        );
        break;
      default:
        break;
    }
    return history;
  };

  const result = {};

  switch (log.loggerType) {
    case "TASK":
      result.history = getTaskHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "HUDDLE":
      result.history = getHuddleHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "CHECKPOINT":
      result.history = getCheckpointHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "LANE":
      result.history = getLaneHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "BACKLOG":
      result.history = getBacklogHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "IMPACT_EFFORT_GROUP_TAG":
      result.history = getImpactEffortGroupHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "INITIATIVE":
      result.history = getInitiativeHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "TAG_GROUP":
      result.history = getTagsHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "MILESTONE":
      result.history = getMilestoneHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    case "METRICS":
      result.history = getMetricsHistory(log);
      result.date = moment(log.createdAt).format("MMMM D YYYY, h:mm A");
      break;
    default:
      result.history = "";
      result.date = "";
      break;
  }
  return result;
};

export const LogHistory = ({ log }) => {
  const dispatch = useDispatch();
  const openUserLogHistory = (user) => {
    dispatch(setSelectUser(user));
  };

  const { history, date } = filterLogHistory({ log, openUserLogHistory });

  return (
    <div className="log-history--log-box">
      <div className="log-history--history">{history}</div>
      <div className="log-history--date">{date}</div>
    </div>
  );
};
