import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { strongCopyData } from "../../../Utils";
import { PerPageThen } from "../../../Constants/Glopalconstants";

const initialState = {
  npsLineChart: [],
  npsPieChart: [],
  rootCauseLineChart: [],
  rootCausePieChart: [],
  metricsLoading: true,

  topRootCauses: null,
  topRootCausesLoading: true,

  milestones: [],
  milestonesCount: 0,
  milestoneLoading: false,

  error: {},
};

export const getMetricsAsync = createAsyncThunk(
  "metrics/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/initiative/chart/${action.surveyID}/${action.id}?page=1&perPage=100&primaryApiKey=${action.apikey}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getTopRootCausesAsync = createAsyncThunk(
  "topCauses/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/initiative/metrics/top-5-root-causes`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getMilestonesAsync = createAsyncThunk(
  "milestone/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/milestone/all/${action.initID}?offset=${action.offset}&limit=${PerPageThen}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return { data: response.data, type: action.type };
  }
);

export const creatMilestonesAsync = createAsyncThunk(
  "milestone/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/milestone`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const updateMileStoneAsync = createAsyncThunk(
  "milestone/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/milestone`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteMileStoneAsync = createAsyncThunk(
  "milestone/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/milestone/${action.id}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    resetMetrics: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetricsAsync.pending, (state, action) => {
        state.metricsLoading = true;
        state.error.metrics = null;
      })
      .addCase(getMetricsAsync.fulfilled, (state, action) => {
        state.npsLineChart = action?.payload?.promoter || [];
        state.npsPieChart = action?.payload?.yearlyPromoter || [];
        state.rootCauseLineChart = action?.payload?.total || [];
        state.rootCausePieChart = action?.payload?.highest || [];
        state.metricsLoading = false;
      })
      .addCase(getMetricsAsync.rejected, (state, action) => {
        state.metricsLoading = false;
        state.error.metrics = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getTopRootCausesAsync.pending, (state, action) => {
        state.topRootCausesLoading = true;
        state.error.topRootCauses = null;
      })
      .addCase(getTopRootCausesAsync.fulfilled, (state, action) => {
        state.topRootCauses = action.payload;
        state.topRootCausesLoading = false;
      })
      .addCase(getTopRootCausesAsync.rejected, (state, action) => {
        state.topRootCausesLoading = false;
        state.error.topRootCauses = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getMilestonesAsync.pending, (state, action) => {
        state.milestoneLoading = true;
      })
      .addCase(getMilestonesAsync.fulfilled, (state, action) => {
        state.milestoneLoading = false;
        state.milestones =
          action.payload.type === "taskMilestones"
            ? [...state.milestones, ...action.payload.data.milestones]
            : action.payload.data.milestones;
        state.milestonesCount = action.payload.data.count;
      })
      .addCase(getMilestonesAsync.rejected, (state, action) => {
        state.milestoneLoading = false;
        state.error.milestones = "Something went wrong";
      })
      .addCase(creatMilestonesAsync.fulfilled, (state, action) => {
        state.milestones = [action.payload, ...state.milestones];
        if (state.milestones.length > PerPageThen) {
          state.milestones.pop();
        }
        state.milestonesCount += 1;
      })
      .addCase(updateMileStoneAsync.fulfilled, (state, action) => {
        const index = state.milestones.findIndex(
          (item) => item.id === action.payload.id
        );
        state.milestones[index] = action.payload;
      })
      .addCase(deleteMileStoneAsync.fulfilled, (state, action) => {
        let milestonesCopy = strongCopyData(state.milestones);
        state.milestones = milestonesCopy.filter(
          (milestone) => milestone.id !== action.payload.id
        );
        state.milestonesCount -= 1;
      });
  },
});

export const { resetMetrics } = metricsSlice.actions;

//Selectors
export const getNpsLineChart = (state) => state.metrics.npsLineChart;
export const getNpsPieChart = (state) => state.metrics.npsPieChart;
export const getRootCauseLineChart = (state) =>
  state.metrics.rootCauseLineChart;
export const getRootCausePieChart = (state) => state.metrics.rootCausePieChart;
export const getMetricsLoading = (state) => state.metrics.metricsLoading;

export const getTopRootCauses = (state) => state.metrics.topRootCauses;
export const getTopRootCausesLoading = (state) =>
  state.metrics.topRootCausesLoading;

export const getMilestones = (state) => state.metrics.milestones;
export const getMilestonesCount = (state) => state.metrics.milestonesCount;
export const getMilestonesLoading = (state) => state.metrics.milestoneLoading;

export const getMetricsError = (state) => state.metrics.error;

export default metricsSlice.reducer;
