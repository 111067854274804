import React, { memo } from "react";
import "./LogHistory.scss";
import moment from "moment";
import ScrollPagination from "../ScrollPagination/ScrollPagination";
import { LogHistory } from "./LogHistory";

const LogHistoryScrollPagination = ({
  fetchMoreData,
  hasMore,
  history,
  useWindow = true,
}) => {
  let newDate = "";
  let isNewDate = true;

  return (
    <ScrollPagination
      loadMore={fetchMoreData}
      hasMore={hasMore}
      useWindow={useWindow}
    >
      <>
        {history.map((log, index) => {
          let dateItem = moment(log?.createdAt).format("MMMM D, YYYY");
          if (newDate !== dateItem) {
            newDate = dateItem;
            isNewDate = true;
          } else {
            isNewDate = false;
          }
          return (
            <div key={log.id + index}>
              {isNewDate && <div className="log-history-header" />}
              <LogHistory log={log} />
            </div>
          );
        })}
      </>
    </ScrollPagination>
  );
};
export default memo(LogHistoryScrollPagination);
