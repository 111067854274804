import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Auth } from "../Pages/Auth";
import { useSelector } from "react-redux";
import { getCurrentUser, getSelectFeedbackName } from "../Store/slices/user";
import BaseLayout from "../Layout/BaseLayout/BaseLayout";
import MainLayout from "../Layout/MainLayout/MainLayout";
import NotfoundPage from "../Pages/NotFoundPage/index";
import LoadingPage from "../Pages/LoadingPage";
import FeedbacksNavigationHandler from '../Components/FeedbacksNavigationHandler/FeedbacksNavigationHandler';

const Feedback = lazy(() => import("../Pages/Feedback"));
const Initiatives = lazy(() => import("../Pages/Initiatives"));
const DeletedInitiatives = lazy(() =>
  import("../Pages/DeletedInitiatives/DeletedInitiatives")
);
const CreateOrEditInitiative = lazy(() =>
  import("../Pages/CreateOrEditInitiative")
);
const CreateOrEditEffortImpactTags = lazy(() =>
  import("../Pages/CreateOrEditEffortImpactTags/CreateOrEditEffortImpactTags")
);
const Initiative = lazy(() => import("../Pages/Initiative"));
const Tasks = lazy(() => import("../Pages/Tasks"));
const Huddles = lazy(() => import("../Pages/Huddles"));
const Metrics = lazy(() => import("../Pages/Metrics"));
const MetricsAndKpi = lazy(() => import("../Pages/Metrics&KPI"));
const Files = lazy(() => import("../Pages/Files"));
const Milestone = lazy(() => import("../Pages/Milestone"));
const PeopleRole = lazy(() => import("../Pages/PeopleRole/PeopleRole"));
const FishAnalysis = lazy(() => import("../Pages/FishAnalysis/FishAnalysis"));
const Overview = lazy(() => import("../Pages/Overview"));
const BackLog = lazy(() => import("../Pages/BackLog/BackLog"));
const CreateOrEditBacklog = lazy(() => import("../Pages/CreateOrEditBackLog"));
const LogsHistory = lazy(() => import("../Pages/LogsHistory/LogsHistory"));
const Dashboard = lazy(() => import("../Pages/Dashboard"));
const Admin = lazy(() => import("../Pages/Admin"));
const Settings = lazy(() => import("../Pages/Settings"));

export const Routers = () => {
  const user = useSelector(getCurrentUser);
  const selectFeedbackName = useSelector(getSelectFeedbackName);

  return (
    <Routes>
      <Route path="/authorization/callback" element={<Auth />} />
      <Route path="/" element={<BaseLayout />}>
        {["/", "/feedbacks"].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={<LoadingPage />}>
                <Feedback />
              </Suspense>
            }
          />
        ))}

        {selectFeedbackName ? (
          <>
            <Route
              path="/initiatives/:status"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Initiatives />
                  </Suspense>
                </MainLayout>
              }
            >
              <Route
                path="table"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <DeletedInitiatives />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="/initiative"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CreateOrEditInitiative />
                </Suspense>
              }
            />

            <Route
              path="/effort-impact-tags"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CreateOrEditEffortImpactTags />
                </Suspense>
              }
            />

            <Route
              path="/initiative/:status/:initID"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Initiative />
                  </Suspense>
                </MainLayout>
              }
            >
              <Route
                path="tasks"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Tasks />
                  </Suspense>
                }
              />
              <Route
                path="huddles"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Huddles />
                  </Suspense>
                }
              />
              <Route
                path="metrics"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Metrics />
                  </Suspense>
                }
              />
              <Route
                path="kpis"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <MetricsAndKpi />
                  </Suspense>
                }
              />
              <Route
                path="files"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Files />
                  </Suspense>
                }
              />
              <Route
                path="people-role"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <PeopleRole />
                  </Suspense>
                }
              />
              <Route
                path="fishbone-rca"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <FishAnalysis />
                  </Suspense>
                }
              />

              <Route
                path="overview"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Overview />
                  </Suspense>
                }
              />
              <Route
                path="milestones"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Milestone />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="/backlogs"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <BackLog />
                </Suspense>
              }
            >
              <Route
                path="backlog"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <CreateOrEditBacklog />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="/logs"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <LogsHistory />
                  </Suspense>
                </MainLayout>
              }
            />

            <Route
              path="/dashboard"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Dashboard />
                  </Suspense>
                </MainLayout>
              }
            />

            {user?.isAdmin && (
              <Route
                path="/admin"
                element={
                  <MainLayout>
                    <Suspense fallback={<LoadingPage />}>
                      <Admin />
                    </Suspense>
                  </MainLayout>
                }
              ></Route>
            )}

            <Route
              path="/settings"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Settings />
                  </Suspense>
                </MainLayout>
              }
            />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/feedbacks" />} />
          </>
        )}
        <Route path="/feedbacks-list" element={<FeedbacksNavigationHandler />} />
        <Route path="*" element={<NotfoundPage />} />
      </Route>
    </Routes>
  );
};
