import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./BaseHeader.scss";
import { WuAppHeader } from "@questionproext/wick-ui-lib";
import {
  getSelectFeedbackName,
  headerInfoSelector,
} from "../../Store/slices/user";
import { removeCookies } from "../../Utils/cookies";
import { TOKEN_NAME } from "../../Constants/Api";
import { getInitiative } from "../../Store/slices/initiative";

const BaseHeader = () => {
  const { categories, myAccount, footer } = useSelector(headerInfoSelector);
  const initiative = useSelector(getInitiative);
  const feedbackName = useSelector(getSelectFeedbackName);
  const { pathname } = useLocation();

  const breadcrumb = useMemo(() => {
    const pathNameArray = pathname.split("/");
    const pathnameWithoutSlash = pathNameArray.length ? pathNameArray[1] : "";

    if (pathnameWithoutSlash === "settings") {
      return [
        {
          name: "Feedbacks",
          url: "/feedbacks-list",
        },
        {
          name: "Settings",
        },
      ];
    }

    if (pathnameWithoutSlash === "admin") {
      return [
        {
          name: "Feedbacks",
          url: "/feedbacks-list",
        },
        {
          name: "Admin",
        },
      ];
    }

    const baseBreadcrumb = [{ name: feedbackName, url: "/feedbacks-list" }];
    const breadCrumbMap = {
      initiatives: [...baseBreadcrumb, { name: "Initiatives" }],
      initiative: [...baseBreadcrumb, { name: "Initiatives", url: "/" }],
      backlogs: [...baseBreadcrumb, { name: "Backlogs" }],
      logs: [...baseBreadcrumb, { name: "Logs" }],
      dashboard: [...baseBreadcrumb, { name: "Dashboard" }],
    };

    const breadCrumbs = breadCrumbMap[pathnameWithoutSlash] || baseBreadcrumb;

    return [
      ...breadCrumbs,
      ...(initiative?.name ? [{ name: initiative.name }] : []),
    ];
  }, [initiative?.name, pathname, feedbackName]);

  const logout = () => {
    removeCookies(TOKEN_NAME);
    window.location.href = "https://www.questionpro.com/a/logout.do";
  };

  if (!categories?.length || !myAccount || !footer) return null;

  return (
    <div className="base-header">
      <WuAppHeader
        productCategories={categories}
        myAccount={myAccount}
        breadcrumbs={breadcrumb.map((item) => ({
          type: "link",
          label: item.name,
          url: item?.url,
        }))}
        footer={footer}
        onLogoutClick={logout}
        activeProductName="Outerloop"
      />
    </div>
  );
};

export default BaseHeader;
