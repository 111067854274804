import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user/index";
import adminSlice from "./slices/admin/index";
import outerLoopSlice from "./slices/outerloop/index";
import settingSlice from "./slices/settings/index";
import initiativesSlice from "./slices/initiatives/index";
import initiativeSlice from "./slices/initiative/index";
import activityFeedSlice from "./slices/activityFeed/index";
import tasksSlice from "./slices/task/index";
import huddleSlice from "./slices/huddle/index";
import metricsSlice from "./slices/metrics/index";
import backlogSlice from "./slices/backlog/index";
import metricsKPISlice from "./slices/metricsKPI/index";
import logsHistorySlice from "./slices/logsHistory/index";
import analyticsSlice from "./slices/analytics/index";
import peopleRoleSlice from "./slices/peopleRole/index";
import fishAnalyticsSlice from "./slices/fishAnalysis/index";
import successOrErrorSlice from "./slices/successOrError/successOrError";

const rootReducer = combineReducers({
  user: userSlice,
  admin: adminSlice,
  outerLoop: outerLoopSlice,
  setting: settingSlice,
  initiatives: initiativesSlice,
  initiative: initiativeSlice,
  activityFeed: activityFeedSlice,
  tasks: tasksSlice,
  huddle: huddleSlice,
  metrics: metricsSlice,
  backlog: backlogSlice,
  metricsKPI: metricsKPISlice,
  logsHistory: logsHistorySlice,
  analytics: analyticsSlice,
  peopleRole: peopleRoleSlice,
  fishAnalytics: fishAnalyticsSlice,
  successOrError: successOrErrorSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
