// export const taskStatus = ["pending", "in-progress", "complete", "closed"];
export const taskStatus = ["pending", "in-progress", "complete", "closed"];
export const taskStatusType = {
  Pending: "pending",
  "In progress": "in-progress",
  Complete: "complete",
  Closed: "closed",
};

export const taskLoadingSkeletonCount = 8;
