import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { strongCopyData } from "../../../Utils";
import { addRequest } from "../../../IndexDB/indexDB";
import { PerPageThen } from "../../../Constants/Glopalconstants";

const initialState = {
  huddles: [],
  huddleLoading: true,
  selectHuddle: null,
  huddlesCount: 0,
  newHuddle: false,
  searchValue: "",
  edit: false,
  clearEditor: true,
  huddlesError: "",
};

export const huddleSortAsync = createAsyncThunk(
  "huddle/huddleSort",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/huddle/sort/${action.sort}/${action.id}?page=${action.page}&perPage=${PerPageThen}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const huddleSearchAsync = createAsyncThunk(
  "huddle/huddleSearch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/huddle/search/${action.initiativeId}?search_query=${action.searchTerm}&page=${action.activePage}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return { data: response.data, page: action.activePage };
  }
);

export const createHuddleAsync = createAsyncThunk(
  "huddle/createByNewHuddle",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/huddle`,
      { ...action.initialHuddle },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return {
      data: response.data,
    };
  }
);

export const updateHuddleAsync = createAsyncThunk(
  "huddle/update",
  async (_, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const huddle = { ...state.getState().huddle.selectHuddle };
    if (huddle.id) {
      const response = await axios.patch(
        `${BASE_URL}/huddle/${huddle.id}`,
        { ...huddle },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return {
        data: response.data,
      };
    }
    return null;
  }
);

export const deleteHuddleAsync = createAsyncThunk(
  "huddle/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/huddle/${action.id}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const huddleSlice = createSlice({
  name: "huddles",
  initialState,
  reducers: {
    resetHuddles: () => initialState,
    addNewHuddle(state, action) {
      state.clearEditor = !state.clearEditor;
      state.selectHuddle = action.payload;
      state.newHuddle = true;
    },
    removeNewHuddle(state, action) {
      const selectNewHuddle = strongCopyData(state.selectHuddle);
      if (selectNewHuddle && action.payload) {
        const newHuddles = strongCopyData(state.huddles).slice(0, 9);
        state.huddles = [selectNewHuddle, ...newHuddles];
        state.huddlesCount += 1;
      }
      state.newHuddle = false;
      state.selectHuddle = null;
    },
    selectCurrentHuddle(state, action) {
      state.selectHuddle = action.payload;
    },
    selectHuddlePeople(state, action) {
      state.selectHuddle.people = action.payload;
    },
    selectHuddleTeams(state, action) {
      state.selectHuddle.teams = action.payload;
    },
    selectHuddleText(state, action) {
      if (state.selectHuddle) {
        state.selectHuddle.description_HTML = action.payload;
      }
    },
    changeSearchValue(state, action) {
      state.searchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(huddleSortAsync.pending, (state, action) => {
        state.huddleLoading = true;
        state.huddlesError = "";
      })
      .addCase(huddleSortAsync.fulfilled, (state, action) => {
        state.huddlesCount = action.payload.count;
        state.huddles = action.payload.rows;
        state.huddleLoading = false;
      })
      .addCase(huddleSortAsync.rejected, (state, action) => {
        state.huddleLoading = false;
        state.huddlesError = "Something went wrong";
      })

      .addCase(huddleSearchAsync.pending, (state, action) => {
        state.huddleLoading = true;
      })
      .addCase(huddleSearchAsync.fulfilled, (state, action) => {
        state.huddlesCount = action.payload.data.count;
        state.huddles = action.payload.data.rows;
        state.huddleLoading = false;
      })
      .addCase(huddleSearchAsync.rejected, (state, action) => {
        state.huddleLoading = false;
      })

      .addCase(createHuddleAsync.fulfilled, (state, action) => {
        state.selectHuddle = action.payload.data;
      })

      .addCase(updateHuddleAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const index = state.huddles.findIndex(
            (item) => item.id === action.payload.data.id
          );
          state.huddles[index] = action.payload.data;
        }
      })
      .addCase(deleteHuddleAsync.fulfilled, (state, action) => {
        const huddles = strongCopyData(state.huddles);
        state.huddles = huddles.filter(
          (huddle) => huddle.id !== action.payload.id
        );
      });
  },
});

export const {
  resetHuddles,
  addNewHuddle,
  removeNewHuddle,
  selectCurrentHuddle,
  selectHuddlePeople,
  selectHuddleTeams,
  selectHuddleText,
  changeSearchValue,
} = huddleSlice.actions;

//Selectors
export const getHuddles = (state) => state.huddle.huddles;

export const getHuddleLoading = (state) => state.huddle.huddleLoading;

export const getHuddlesAllCount = (state) => state.huddle.huddlesCount;

export const getNewHuddle = (state) => state.huddle.newHuddle;

export const getSelectHuddle = (state) => state.huddle.selectHuddle;

export const getClearEditor = (state) => state.huddle.clearEditor;

export const getSearchValue = (state) => state.huddle.searchValue;

export const getHuddlesError = (state) => state.huddle.huddlesError;

export default huddleSlice.reducer;
