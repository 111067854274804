import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { strongCopyData } from "../../../Utils";

const initialState = {
  backlogs: [],
  backlogsLoading: false,
  backlogsAllCount: 1,
  backlogCount: 0,
  backlogInitiativeCount: 0,
  backlogsPage: 1,
  backlogsError: "",

  backlog: null,
  backlogLoading: true,
  backlogStatus: "backlog",
  backlogTags: [],
  selectTagsForBacklog: [],
};

export const getBacklogsAsync = createAsyncThunk(
  "backlogs/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/backlog/feedback?page=${action.page}&perPage=${action.perPage}`,
        {
          tags: action.backLogTags,
          status: action.backLogStatus,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getBacklogAsync = createAsyncThunk(
  "backlog/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/backlog/${action.backlogId}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getBacklogFilterCountAsync = createAsyncThunk(
  "backlogFilterCount/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(`${BASE_URL}/backlog/filter`, {
        headers: {
          "x-feedback": feedbackId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const createBacklogAsync = createAsyncThunk(
  "createBacklog/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/backlog`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const updateBacklogAsync = createAsyncThunk(
  "updateBacklog/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.patch(
        `${BASE_URL}/backlog/${action.backlogId}`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteBacklogAsync = createAsyncThunk(
  "deleteBacklog/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.delete(`${BASE_URL}/backlog/${action.id}`, {
        headers: {
          "x-feedback": feedbackId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const likeBacklogAsync = createAsyncThunk(
  "likeBacklog/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(`${BASE_URL}/backlog/like`, action, {
        headers: {
          "x-feedback": feedbackId,
        },
      });
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

const backlogSlice = createSlice({
  name: "backlog",
  initialState,
  reducers: {
    resetBacklog: () => initialState,
    emptySelectBackLog(state) {
      state.backlog = null;
      state.backlogLoading = true;
    },
    selectTagsForBacklog(state, action) {
      state.selectTagsForBacklog = action.payload;
    },
    addBackLogTags(state, action) {
      state.backlogTags = action.payload;
    },
    addBackLogStatus(state, action) {
      state.backlogStatus = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBacklogsAsync.pending, (state, action) => {
        state.backlogsLoading = true;
      })
      .addCase(getBacklogsAsync.fulfilled, (state, action) => {
        state.backlogs = action.payload?.backlogs && [
          ...state.backlogs,
          ...action.payload.backlogs,
        ];
        state.backlogsAllCount = action?.payload?.count;
        state.backlogsPage += 1;
        state.backlogsLoading = false;
      })
      .addCase(getBacklogsAsync.rejected, (state, action) => {
        state.backlogsLoading = false;
        state.backlogsError = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getBacklogFilterCountAsync.fulfilled, (state, action) => {
        state.backlogCount = action.payload.backlogCount;
        state.backlogInitiativeCount = action.payload.backlogInitiativeCount;
      })
      .addCase(getBacklogFilterCountAsync.rejected, (state, action) => {
        state.backlogsError = `${action.payload?.statusCode} - ${action.payload?.message}`;
      })
      .addCase(getBacklogAsync.pending, (state, action) => {
        state.backlogLoading = true;
      })
      .addCase(getBacklogAsync.fulfilled, (state, action) => {
        state.backlog = action.payload;
        state.backlogLoading = false;
      })
      .addCase(getBacklogAsync.rejected, (state, action) => {
        state.backlogsError = `${action.payload?.statusCode} - ${action.payload?.message}`;
        state.backlogLoading = false;
      })

      .addCase(createBacklogAsync.fulfilled, (state, action) => {
        state.backlogs = [action.payload, ...state.backlogs];
        state.backlogsAllCount += 1;
        state.backlogCount += 1;
      })
      .addCase(updateBacklogAsync.fulfilled, (state, action) => {
        const index = state.backlogs.findIndex(
          (item) => item.id === action.payload.id
        );
        state.backlogs[index] = action.payload;
        state.backlogs[index].tags = action.payload.newTags;
      })
      .addCase(deleteBacklogAsync.fulfilled, (state, action) => {
        state.backlogs = strongCopyData(state.backlogs).filter(
          (backlog) => backlog.id !== action.payload?.id
        );
        state.backlogsAllCount -= 1;
        if(action.payload.type === "active"){
          state.backlogInitiativeCount -=1
        }
        if(action.payload.type === "pending"){
          state.backlogCount -= 1;
        }
        state.backlogLoading = false;
      })
      .addCase(likeBacklogAsync.fulfilled, (state, action) => {
        const index = state.backlogs.findIndex(
          (item) => item.id === action.payload.backlogId
        );
        state.backlogs[index].canLike = false;
        state.backlogs[index].totalLikes += 1;
      });
  },
});

export const {
  resetBacklog,
  emptySelectBackLog,
  selectTagsForBacklog,
  addBackLogTags,
  addBackLogStatus,
} = backlogSlice.actions;

//Selectors

export const getBacklogs = (state) => state.backlog.backlogs;
export const getBacklogsLoading = (state) => state.backlog.backlogsLoading;
export const getBacklogsAllCount = (state) => state.backlog.backlogsAllCount;
export const getBacklogCount = (state) => state.backlog.backlogCount;
export const getBacklogInitiativeCount = (state) =>
  state.backlog.backlogInitiativeCount;
export const getBacklogsPage = (state) => state.backlog.backlogsPage;
export const getBacklogsError = (state) => state.backlog.backlogsError;
export const getBacklog = (state) => state.backlog.backlog;
export const getBacklogLoading = (state) => state.backlog.backlogLoading;
export const getBacklogStatus = (state) => state.backlog.backlogStatus;
export const getBacklogTags = (state) => state.backlog.backlogTags;
export const getSelectTagsForBacklog = (state) =>
  state.backlog.selectTagsForBacklog;

export default backlogSlice.reducer;
