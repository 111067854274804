import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const NotfoundPage = () => {
  return (
    <div className={"not-found"}>
      This page doesn't exist. Go{" "}
      <Link
        className={"not-found-link"}
        to="/"
        onClick={() => localStorage.removeItem("pathname")}
      >
        Home
      </Link>
    </div>
  );
};

export default NotfoundPage;
