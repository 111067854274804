import { memo, useState } from "react";
import "./BaseNavBar.scss";
import { ReactComponent as MenuList } from "../../../Assets/Icons/MenuList.svg";
import { getClassNameByParam, triggerClassName } from "../../../Utils";
import OutsideClickHandler from "react-outside-click-handler";

const BaseNavBar = ({ tabs, tabActive, onTabSelect, navbarName }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleClick = (tab) => {
    onTabSelect(tab);
    setIsOpenMenu(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpenMenu(false)}>
      <div
        className={`base-nav-bar ${getClassNameByParam(
          isOpenMenu,
          "base-nav-bar--open"
        )}`}
      >
        <div className={"base-nav-bar--mobile"}>
          <span onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <MenuList />
          </span>
        </div>
        <div className={"base-nav-bar-hr"} />
        {tabs?.map((tab, index) => (
          <div
            id={tab.type?.toLowerCase() || "tab-item"}
            key={index}
            className={`base-nav-bar--tab ${triggerClassName(
              tabActive?.type || tabActive,
              tab.type,
              "base-nav-bar--tab-active"
            )} ${triggerClassName(
              tab.type,
              "deleted",
              "base-nav-bar--tab-delete"
            )}`}
            onClick={() => handleClick(tab)}
          >
            <span>{tab.title}</span>
            {navbarName === "initiatives" && (
              <span className={"base-nav-bar--value"}>{tab?.value || 0}</span>
            )}
          </div>
        ))}
      </div>
    </OutsideClickHandler>
  );
};

export default memo(BaseNavBar);
