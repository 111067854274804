import React, { useEffect, useState } from "react";
import Loader from "../../Assets/loader.gif";
import "./styles.scss";

const LoadingPage = () => {
  const [isTimeOut, setIsTimeout] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeout(true);
    }, "3000");

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loading-page">
      <img width={80} height={20} src={Loader} alt={"Loader"} />
      {isTimeOut ? <span>Waiting</span> : null}
    </div>
  );
};

export default LoadingPage;
