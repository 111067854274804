import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "@questionproext/wick-ui-lib/dist/esm/wick-ui-bundle.css";
import "@questionproext/wick-ui-icons/dist/icomoon/css/wick-ui-icon.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./Store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
