import { useNavigate } from "react-router-dom";

const useCustomNavigate = () => {
  const navigate = useNavigate();

  const navigateToInitiative = ({
    initiativeID,
    status,
    page = "tasks",
    state,
  }) => {
    navigate(
      {
        pathname: `/initiative/${status}/${initiativeID}/${page}`,
      },
      { state }
    );
  };

  const navigateTo = ({ pathname, hash, search, state }) => {
    navigate(
      {
        pathname,
        search,
        hash,
      },
      { state }
    );
  };

  return {
    navigateToInitiative,
    navigateTo,
  };
};

export default useCustomNavigate;
