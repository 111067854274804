import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { PerPageThen } from "../../../Constants/Glopalconstants";
import { strongCopyData } from "../../../Utils";
import uuid from "react-uuid";

const initialState = {
  metricsYearsAndMonth: [],
  metricsGraphic: [],
  metrics: [],
  metricsCount: 0,
  metricsLoading: true,
  metricsKPIs: [],
  metricsKPIsPage: 0,
  // metricsKPIsCount: 0,
  metricsKPIsLoading: true,
  metricsKPIsSortBy: {
    year: "",
    month: "",
  },
  // draftMetricsKPI: null,
  errors: {},
};

export const getMetricsKPIFilterAsync = createAsyncThunk(
  "getMetricsKPIFilterAsync/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/metrics/KPIFilters/${action.initID}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getMetricsKPIFAsync = createAsyncThunk(
  "getMetricsKPIFAsync/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/metrics/KPIData/${action.initID}?year=${
        action.year || ""
      }&month=${action.month || ""}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getMetricsAsync = createAsyncThunk(
  "getMetricsAsync/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/metrics/initiative/${action.initID}?offset=${
        action.offset
      }&limit=${action.limit || PerPageThen}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createMetricsData = createAsyncThunk(
  "createMetricsAsync/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/metrics`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getKPIAsync = createAsyncThunk(
  "getKPIAsync/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/metrics/rows/${action.initID}?page=${action.page}&perPage=${PerPageThen}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createKPIAsync = createAsyncThunk(
  "createKPIAsync/post",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.post(
        `${BASE_URL}/metrics/row`,
        {
          date: action.date,
          initiativeId: action.initID,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue({
        error: err.response.data,
        // priorityIndex: action.priorityIndex,
      });
    }
  }
);

export const updateKPIDateAsync = createAsyncThunk(
  "createKPIAsync/patch",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.patch(
        `${BASE_URL}/metrics/row`,
        {
          date: action.date,
          initiativeId: action.initiativeId,
          id: action.KPIID,
          priorityIndex: action.priorityIndex,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue({
        error: err.response.data,
        // priorityIndex: action.priorityIndex,
      });
    }
  }
);

export const updateKPIAsync = createAsyncThunk(
  "updateKPIAsync/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/metrics/row-value`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateKPIWidthAsync = createAsyncThunk(
  "updateKPIAsync/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/metrics/width/${action.id}`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteKPIAsync = createAsyncThunk(
  "deleteKPIAsync/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(
      `${BASE_URL}/metrics/row/${action.id}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const metricsKPISlice = createSlice({
  name: "metricsKPI",
  initialState,
  reducers: {
    resetMetricsKPI: () => initialState,

    addNewMetricsKPI: (state, action) => {
      state.metricsKPIs.splice(action.payload.index, 0, {
        id: action.payload.id,
        index: action.payload.index,
        values: [],
      });
    },
    deleteMetricsKPI: (state, action) => {
      state.metricsKPIs = state.metricsKPIs.filter(
        (metricsKPI) => metricsKPI.id !== action.payload.id
      );
      if (!state.metricsKPIs.length) {
        state.metricsKPIs = [
          {
            id: uuid(),
            index: 0,
            values: [],
          },
        ];
      }
    },
    metricsKPIsSort: (state, action) => {
      state.metricsKPIsSortBy = {
        ...state.metricsKPIsSortBy,
        [action.payload.key]: action.payload.value,
      };
    },
    changeMetricsKPI: (state, action) => {
      state.metrics = state.metrics.map((el) => {
        if (el.id === action.payload.id) {
          el.width = action.payload.width;
        }
        return el;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetricsKPIFilterAsync.fulfilled, (state, action) => {
        state.metricsYearsAndMonth = action.payload.years;
      })
      .addCase(getMetricsKPIFAsync.fulfilled, (state, action) => {
        state.metricsGraphic = action.payload;
      })

      .addCase(getMetricsAsync.pending, (state, action) => {
        state.metricsLoading = true;
      })
      .addCase(getMetricsAsync.fulfilled, (state, action) => {
        state.metrics = [...state.metrics, ...action.payload.metrics];
        // state.metricsCount = action.payload.count;
        state.metricsLoading = false;
      })
      .addCase(getMetricsAsync.rejected, (state, action) => {
        state.metricsLoading = false;
        state.errors.metricsKPI = true;
      })
      .addCase(createMetricsData.fulfilled, (state, action) => {
        state.metrics = action.payload.allMetrics;
        // state.metrics = [...action.payload.allMetrics, ...state.metrics];
        // if (state.metrics.length > 10) {
        //   state.metrics.pop();
        // }
        // state.metricsCount += 1;
      })
      .addCase(getKPIAsync.pending, (state, action) => {
        state.metricsKPIsLoading = true;
      })
      .addCase(getKPIAsync.fulfilled, (state, action) => {
        state.metricsKPIs = action.payload.rows.length
          ? [...state.metricsKPIs, ...action.payload.rows]
          : [
              {
                id: uuid(),
                index: 0,
                values: [],
              },
            ];
        // state.metricsKPIsCount = action.payload.count;
        state.metricsKPIsPage = action.payload.page;
        state.metricsKPIsLoading = false;
      })
      .addCase(getKPIAsync.rejected, (state, action) => {
        state.metricsKPIsLoading = false;
        state.errors.metricsKPI = true;
      })
      .addCase(createKPIAsync.fulfilled, (state, action) => {
        state.metricsKPIs = [
          {
            id: action.payload.id,
            date: action.payload.date,
            values: [],
          },
          ...state.metricsKPIs,
        ];
        // state.metricsKPIsCount += 1;
      })
      .addCase(deleteKPIAsync.fulfilled, (state, action) => {
        let kpiCopy = strongCopyData(state.metricsKPIs);
        state.metricsKPIs = kpiCopy.filter(
          (metricsKPI) => metricsKPI.id !== action.payload.id
        );
        // state.metricsKPIsCount -= 1;
        // state.draftMetricsKPI = null;
      })
      .addCase(updateKPIDateAsync.fulfilled, (state, action) => {
        const newMetricsKPIs = state.metricsKPIs;
        newMetricsKPIs.splice(action.payload.priorityIndex, 1, action.payload);
        state.metricsKPIs = newMetricsKPIs;
      });
  },
});

export const {
  resetMetricsKPI,
  addNewMetricsKPI,
  deleteMetricsKPI,
  metricsKPIsSort,
  changeMetricsKPI,
} = metricsKPISlice.actions;

//Selectors

export const getMetricsYearsAndMonth = (state) =>
  state.metricsKPI.metricsYearsAndMonth;
export const getMetricsGraphic = (state) => state.metricsKPI.metricsGraphic;
export const getMetrics = (state) => state.metricsKPI.metrics;
// export const getMetricsCount = (state) => state.metricsKPI.metricsCount;
export const getMetricsLoading = (state) => state.metricsKPI.metricsLoading;
export const getMetricsKPIs = (state) => state.metricsKPI.metricsKPIs;
export const getMetricsKPIsPage = (state) => state.metricsKPI.metricsKPIsPage;

// // export const getMetricsKPIsCount = (state) => state.metricsKPI.metricsKPIsCount;
export const getMetricsKPIsLoading = (state) =>
  state.metricsKPI.metricsKPIsLoading;
export const getMetricsKPIsSortBy = (state) =>
  state.metricsKPI.metricsKPIsSortBy;

export const getMetricKPIErrors = (state) => state.metricsKPI.errors;

export default metricsKPISlice.reducer;
