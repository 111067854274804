import { io } from "socket.io-client";
import { SOCKET_URL, TOKEN_NAME } from "./Constants/Api";
import { getCookies } from "./Utils/cookies";
export let socket;

export const initiateSocketConnection = () => {
  const token = getCookies(TOKEN_NAME);
  socket = io(SOCKET_URL || "https://outerloop-api-staging.questionpro.com", {
    path: `/socket.io`,
    forceNew: true,
    reconnectionAttempts: 3,
    timeout: 2000,
    transports: ["websocket"],
    query: {
      token,
    },
  });
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const subscribeToSocket = (key, data) => {
  socket.emit(key, data);
};
