import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./UserLogHistoryModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NoData } from "../../../Assets/Icons/NoData.svg";
import { PerPageTwenty } from "../../../Constants/Glopalconstants";
import LoadingPage from "../../../Pages/LoadingPage";
import BaseModal from "../BaseModal/BaseModal";
import InitiativeAnalytic from "./InitiativeAnalytic";
import TaskAnalytic from "./TaskAnalytic";
import LogHistoryScrollPagination from "./LogHistoryScrollPagination";
import {
  getUserLogAnalytic,
  getUserLogAnalyticAsync,
  getUserLogAnalyticLoading,
  getUserLogsHistory,
  getUserLogsHistoryAsync,
  getUserLogsHistoryCount,
  getUserLogsHistoryLoading,
  getUserLogsHistoryPage,
  resetUserLogsHistory,
} from "../../../Store/slices/logsHistory";
import { getUserAnalyticsAsync } from "../../../Store/slices/analytics";
import { setSelectUser } from "../../../Store/slices/user";
import BaseNavBar from "../BaseNavBar/BaseNavBar";

const UserLogHistoryModal = ({ user }) => {
  const dispatch = useDispatch();
  const userLogAnalytic = useSelector(getUserLogAnalytic);
  const userLogAnalyticLoading = useSelector(getUserLogAnalyticLoading);
  const userLogsHistory = useSelector(getUserLogsHistory);
  const userLogsHistoryPage = useSelector(getUserLogsHistoryPage);
  const userLogsHistoryCount = useSelector(getUserLogsHistoryCount);
  const userLogsHistoryLoading = useSelector(getUserLogsHistoryLoading);
  const [activeTab, setActiveTab] = useState("user_log");

  const calcIntWidth = (count = 0) => {
    return `${
      (count * 100) /
      (userLogAnalytic?.initiativeOwner +
        userLogAnalytic?.initiativeContributor)
    }%`;
  };

  const calcTaskWidth = (count = 0) => {
    return `${
      (count * 100) /
      (userLogAnalytic?.taskOwner + userLogAnalytic?.taskContributor)
    }%`;
  };

  const fetchMoreData = useCallback(() => {
    dispatch(
      getUserLogsHistoryAsync({
        page: userLogsHistoryPage + 1,
        userId: user.userID,
      })
    );
  }, [dispatch, user.userID, userLogsHistoryPage]);

  const getInitiativeAnalytic = useCallback(
    (type) => {
      dispatch(
        getUserAnalyticsAsync({
          type,
          emailAddress: user.emailAddress,
          offset: 0,
        })
      );
    },
    [dispatch, user.emailAddress]
  );

  const getTaskAnalytic = useCallback(
    (type) => {
      dispatch(
        getUserAnalyticsAsync({
          type,
          emailAddress: user.emailAddress,
          offset: 0,
        })
      );
    },
    [dispatch, user.emailAddress]
  );

  const tabs = useMemo(
    () => [
      { title: "User Log", type: "user_log" },
      { title: "Initiative Owner", type: "initiative_owner" },
      { title: "Initiative Contributor", type: "initiative_contributor" },
      { title: "Task Owner", type: "task_owner" },
      { title: "Task Contributor", type: "task_contributor" },
    ],
    []
  );

  const onTabSelect = useCallback(
    (tab) => {
      setActiveTab(tab.type);
      if (
        tab.type === "initiative_owner" ||
        tab.type === "initiative_contributor"
      ) {
        getInitiativeAnalytic(tab.type);
      }
      if (tab.type === "task_owner" || tab.type === "task_contributor") {
        getTaskAnalytic(tab.type);
      }
    },
    [getInitiativeAnalytic, getTaskAnalytic]
  );

  const onCloseModal = () => {
    dispatch(setSelectUser(null));
  };

  useEffect(() => {
    dispatch(getUserLogAnalyticAsync({ emailAddress: user.emailAddress }));
    dispatch(getUserLogsHistoryAsync({ page: 1, userId: user.userID }));
    return () => {
      dispatch(resetUserLogsHistory());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <BaseModal
      visible={!!user}
      headerTitle={`${user.emailAddress} Log History`}
      isLoading={userLogsHistoryLoading}
      onCloseModal={onCloseModal}
    >
      <div className={"user-log-history-modal"}>
        <>
          <div className={"user-log-history-modal--header"}>
            <BaseNavBar
              tabs={tabs}
              tabActive={activeTab}
              onTabSelect={onTabSelect}
            />
          </div>
          {userLogAnalyticLoading ? (
            <div className={"user-log-history-modal--loading-block"}>
              <LoadingPage />
            </div>
          ) : (
            <>
              <div className={"user-log-history-modal--total-count-block"}>
                <div
                  className={"user-log-history-modal--total-count--init-block"}
                >
                  <p
                    className={
                      "user-log-history-modal--total-count-block--title"
                    }
                  >
                    Initiative
                  </p>

                  <div
                    className={"user-log-history-modal--total-count-info-block"}
                  >
                    <div
                      className={
                        "user-log-history-modal--total-count-info-block--init-owner"
                      }
                    >
                      <div
                        className={
                          "user-log-history-modal--total-count-info-block--init-owner-doth"
                        }
                      />
                      <p>{`Owner - ${userLogAnalytic?.initiativeOwner}`}</p>
                    </div>

                    <div
                      className={
                        "user-log-history-modal--total-count-info-block--init-contributor"
                      }
                    >
                      <div
                        className={
                          "user-log-history-modal--total-count-info-block--init-contributor-doth"
                        }
                      />
                      <p>
                        {`Contributor -
                    ${userLogAnalytic?.initiativeContributor}`}
                      </p>
                    </div>
                  </div>
                  <div className={"user-log-history-modal--total-count"}>
                    {!userLogAnalytic?.initiativeOwner &&
                    !userLogAnalytic?.initiativeContributor ? (
                      <p
                        className={
                          "user-log-history-modal--total-count--empty-title"
                        }
                      >
                        no initiative assigned
                      </p>
                    ) : null}

                    {userLogAnalytic?.initiativeOwner ? (
                      <div
                        style={{
                          width: calcIntWidth(userLogAnalytic?.initiativeOwner),
                        }}
                        className={
                          "user-log-history-modal--total-count--init-owner"
                        }
                      >
                        {userLogAnalytic?.initiativeOwner}
                      </div>
                    ) : null}
                    {userLogAnalytic?.initiativeContributor ? (
                      <div
                        style={{
                          width: calcIntWidth(
                            userLogAnalytic?.initiativeContributor
                          ),
                        }}
                        className={
                          "user-log-history-modal--total-count--init-contr"
                        }
                      >
                        {userLogAnalytic?.initiativeContributor}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div
                  className={"user-log-history-modal--total-count--task-block"}
                >
                  <p
                    className={
                      "user-log-history-modal--total-count-block--title"
                    }
                  >
                    Task
                  </p>

                  <div
                    className={"user-log-history-modal--total-count-info-block"}
                  >
                    <div
                      className={
                        "user-log-history-modal--total-count-info-block--task-owner"
                      }
                    >
                      <div
                        className={
                          "user-log-history-modal--total-count-info-block--task-owner-doth"
                        }
                      />
                      <p>{`Owner - ${userLogAnalytic?.taskOwner}`}</p>
                    </div>

                    <div
                      className={
                        "user-log-history-modal--total-count-info-block--task-contributor"
                      }
                    >
                      <div
                        className={
                          "user-log-history-modal--total-count-info-block--task-contributor-doth"
                        }
                      />
                      <p>
                        {`Contributor -
                    ${userLogAnalytic?.taskContributor}`}
                      </p>
                    </div>
                  </div>
                  <div className={"user-log-history-modal--total-count"}>
                    {!userLogAnalytic?.taskOwner &&
                    !userLogAnalytic?.taskContributor ? (
                      <p
                        className={
                          "user-log-history-modal--total-count--empty-title"
                        }
                      >
                        no task assigned
                      </p>
                    ) : null}

                    {userLogAnalytic?.taskOwner ? (
                      <div
                        style={{
                          width: calcTaskWidth(userLogAnalytic?.taskOwner),
                        }}
                        className={
                          "user-log-history-modal--total-count--task-owner"
                        }
                      >
                        {userLogAnalytic?.taskOwner}
                      </div>
                    ) : null}
                    {userLogAnalytic?.taskContributor ? (
                      <div
                        style={{
                          width: calcTaskWidth(
                            userLogAnalytic?.taskContributor
                          ),
                        }}
                        className={
                          "user-log-history-modal--total-count--task-contr"
                        }
                      >
                        {userLogAnalytic?.taskContributor}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className={"user-log-history-modal--content"}>
            {activeTab === "user_log" && (
              <>
                {!userLogsHistory.length && !userLogsHistoryLoading ? (
                  <div className={"no-data"}>
                    <NoData />
                  </div>
                ) : (
                  <>
                    <LogHistoryScrollPagination
                      fetchMoreData={fetchMoreData}
                      hasMore={
                        userLogsHistory &&
                        userLogsHistoryCount > PerPageTwenty &&
                        userLogsHistory.length < userLogsHistoryCount &&
                        !userLogsHistoryLoading
                      }
                      history={userLogsHistory}
                      useWindow={false}
                    />
                    {userLogsHistoryLoading && (
                      <div className={"user-log-history-modal--loading-block"}>
                        <LoadingPage />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {(activeTab === "initiative_owner" ||
              activeTab === "initiative_contributor") && (
              <InitiativeAnalytic
                emailAddress={user.emailAddress}
                type={activeTab}
              />
            )}

            {(activeTab === "task_owner" ||
              activeTab === "task_contributor") && (
              <TaskAnalytic emailAddress={user.emailAddress} type={activeTab} />
            )}
          </div>
        </>
      </div>
    </BaseModal>
  );
};

export default UserLogHistoryModal;
