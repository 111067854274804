import { memo, useEffect } from "react";
import "./ShowToasterMessage.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestErrorMessage,
  getRequestSuccessesMessage,
  resetStore,
} from "../../../Store/slices/successOrError/successOrError";
import toast, { Toaster } from "react-hot-toast";
import { ReactComponent as Success } from "../../../Assets/Icons/SuccessIcon.svg";
import { ReactComponent as Error } from "../../../Assets/Icons/ErrorIcon.svg";

const ShowToasterMessage = () => {
  const dispatch = useDispatch();

  const requestSuccessesMessage = useSelector(getRequestSuccessesMessage);
  const requestErrorMessage = useSelector(getRequestErrorMessage);

  useEffect(() => {
    if (requestSuccessesMessage) {
      toast.success(requestSuccessesMessage, {
        style: {
          width: "922px",
          textAlign: "left",
          fontSize: "12px",
          borderRadius: "3px",
          background: "#DFF2BF",
          color: "#270",
        },
        icon: <Success />,
        className: "custom-toast-status",
      });
      dispatch(resetStore());
    }
    if (requestErrorMessage) {
      toast.error(requestErrorMessage, {
        style: {
          width: "922px",
          textAlign: "left",
          fontSize: "12px",
          borderRadius: "3px",
          background: "#FFBABA",
          color: "#D8000C",
        },
        icon: <Error />,

        className: "custom-toast-status",
      });
      dispatch(resetStore());
    }
  }, [dispatch, requestErrorMessage, requestSuccessesMessage]);

  return <Toaster position="top-center" reverseOrder={false} />;
};

export default memo(ShowToasterMessage);
