import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { strongCopyData } from "../../../Utils";
import { PerPageThen } from "../../../Constants/Glopalconstants";

const initialState = {
  initiative: null,
  backLogInitiative: null,
  initiativeLoading: true,
  initiativeError: "",

  initiativeFiles: [],
  initiativeFilesCount: 0,
  initiativeUploadFiles: [],
  initiativeUploadFilesProgress: 0,
  initiativeFilesLoading: true,

  initiativeImpactEffortGroup: [],
  initiativeImpactEffortGroupLoading: true,

  initiativeTags: [],
  initiativeTagsLoading: true,
  initiativeTagsError: "",
};

export const getInitiativeAsync = createAsyncThunk(
  "initiative/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/initiative/${action.initID}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getInitiativeImpactEffortGroupAsync = createAsyncThunk(
  "initiativeImpactEffortGroup/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/impact-effort-group/initiative/${action.initID}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const createInitiativeAsync = createAsyncThunk(
  "initiative/create",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(`${BASE_URL}/initiative`, action, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateInitiativeAsync = createAsyncThunk(
  "initiative/update",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/initiative/${action.initID}`,
      { ...action.initiativeData },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateInitiativeStatusAsync = createAsyncThunk(
  "initiativeStatus/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/initiative/status/${action.initID}`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateInitiativeStatusHealthAsync = createAsyncThunk(
  "initiativeHealthStatus/update",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/initiative/${action.initID}`,
      { ...action.initiativeData },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteInitiativeAsync = createAsyncThunk(
  "initiative/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/initiative/${action.id}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const recoverInitiativeAsync = createAsyncThunk(
  "initiative/recover",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/initiative/recover/${action.id}`,
      {},
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitiativeTagAsync = createAsyncThunk(
  "initiativeTag/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/tags/initiative/${action.initID}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createInitTagsAsync = createAsyncThunk(
  "initiativeTag/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/tags/initiative/${action.initID}`,
      { tags: action.tags },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const uploadInitFileAsync = createAsyncThunk(
  "initiativeFile/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/initiative/upload`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitFileAsync = createAsyncThunk(
  "initiativeFile/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/initiative/${action.initiativeId}/files?offset=${action.offset}&limit=${PerPageThen}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteInitFileAsync = createAsyncThunk(
  "initiativeFile/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(
      `${BASE_URL}/initiative/file/${action.fileId}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const initiativesSlice = createSlice({
  name: "initiative",
  initialState,
  reducers: {
    resetInitiative: () => initialState,
    resetInitiativeFiles(state) {
      state.initiativeFiles = [];
      state.initiativeFilesCount = 0;
      state.initiativeUploadFiles = [];
      state.initiativeUploadFilesProgress = 0;
      state.initiativeFilesLoading = true;
    },
    resetInitiativeUploadFiles(state) {
      state.initiativeUploadFiles = [];
      state.initiativeUploadFilesProgress = 0;
    },

    selectBackLogInitiative(state, action) {
      state.backLogInitiative = action.payload;
    },
    addInitiativeUploadFiles(state, action) {
      state.initiativeUploadFiles = action.payload;
    },
    updateInitiativeUploadFiles(state, action) {
      const copyData = [...state.initiativeUploadFiles];
      if (copyData.length) {
        const index = copyData?.findIndex(
          (item) => item.name === action.payload.name
        );
        if (
          copyData[index].progress !== action.payload.progress &&
          action.payload.progress === 100
        ) {
          state.initiativeUploadFilesProgress +=
            100 / state.initiativeUploadFiles.length;
        }
        if (copyData[index].progress !== action.payload.progress) {
          copyData[index] = action.payload;
          state.initiativeUploadFiles = copyData;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitiativeAsync.pending, (state, action) => {
        state.initiativeLoading = true;
      })
      .addCase(getInitiativeAsync.fulfilled, (state, action) => {
        state.initiative = action.payload;
        state.initiativeLoading = false;
      })
      .addCase(getInitiativeAsync.rejected, (state, action) => {
        state.initiativeError = `${action.payload?.statusCode} - ${action.payload?.message}`;
        state.initiativeLoading = false;
      })
      .addCase(getInitiativeImpactEffortGroupAsync.pending, (state, action) => {
        state.initiativeImpactEffortGroupLoading = true;
      })
      .addCase(
        getInitiativeImpactEffortGroupAsync.fulfilled,
        (state, action) => {
          state.initiativeImpactEffortGroup = action.payload;
          state.initiativeImpactEffortGroupLoading = false;
        }
      )
      .addCase(
        getInitiativeImpactEffortGroupAsync.rejected,
        (state, action) => {
          state.initiativeImpactEffortGroupLoading = false;
        }
      )
      .addCase(updateInitiativeStatusAsync.fulfilled, (state, action) => {
        state.initiative.status = action.payload?.status;
        state.initiative.solution = action.payload?.reason;
      })
      .addCase(updateInitiativeStatusHealthAsync.fulfilled, (state, action) => {
        state.initiative.healthStatus = action.payload.healthStatus;
      })
      .addCase(getInitiativeTagAsync.pending, (state, action) => {
        state.initiativeTagsLoading = true;
      })
      .addCase(getInitiativeTagAsync.fulfilled, (state, action) => {
        state.initiativeTags = action.payload?.tags || [];
        state.initiativeTagsLoading = false;
      })
      .addCase(getInitiativeTagAsync.rejected, (state, action) => {
        state.initiativeTagsError = "Something went wrong";
        state.initiativeTagsLoading = false;
      })

      .addCase(createInitTagsAsync.fulfilled, (state, action) => {
        state.initiativeTags = action.payload.tags;
      })
      .addCase(createInitTagsAsync.rejected, (state, action) => {
        state.initiativeTagsError = "Something went wrong";
        state.initiativeTagsLoading = false;
      })
      .addCase(uploadInitFileAsync.fulfilled, (state, action) => {
        state.initiativeFiles = [action.payload, ...state.initiativeFiles];
        if (state.initiativeFiles.length > PerPageThen) {
          state.initiativeFiles.pop();
        }
        state.initiativeFilesCount += 1;
        state.initiativeUploadFiles = [];
        state.initiativeUploadFilesProgress = 0;
      })
      .addCase(getInitFileAsync.pending, (state, action) => {
        state.initiativeFilesLoading = true;
      })
      .addCase(getInitFileAsync.fulfilled, (state, action) => {
        state.initiativeFiles = action.payload.files;
        state.initiativeFilesCount = action.payload.count;
        state.initiativeFilesLoading = false;
      })
      .addCase(getInitFileAsync.rejected, (state, action) => {
        state.initiativeFilesLoading = false;
      })
      .addCase(deleteInitFileAsync.pending, (state, action) => {
        state.initiativeFilesLoading = true;
      })
      .addCase(deleteInitFileAsync.fulfilled, (state, action) => {
        let fileCopy = strongCopyData(state.initiativeFiles);
        state.initiativeFiles = fileCopy.filter(
          (file) => file.id !== action.payload.id
        );
        state.initiativeFilesLoading = false;
      })
      .addCase(deleteInitFileAsync.rejected, (state, action) => {
        state.initiativeFilesLoading = false;
      });
  },
});

export const {
  resetInitiative,
  resetInitiativeFiles,
  resetInitiativeUploadFiles,
  selectBackLogInitiative,
  addInitiativeUploadFiles,
  updateInitiativeUploadFiles,
} = initiativesSlice.actions;

//Selectors
export const getInitiative = (state) => state.initiative.initiative;
export const getBackLogInitiative = (state) =>
  state.initiative.backLogInitiative;
export const getInitiativeLoading = (state) =>
  state.initiative.initiativeLoading;
export const getInitiativeError = (state) => state.initiative.initiativeError;
export const getInitiativeImpactEffortGroup = (state) =>
  state.initiative.initiativeImpactEffortGroup;
export const getInitiativeImpactEffortGroupLoading = (state) =>
  state.initiative.initiativeImpactEffortGroupLoading;
export const getInitiativeFiles = (state) => state.initiative.initiativeFiles;
export const getInitiativeUploadFiles = (state) =>
  state.initiative.initiativeUploadFiles;
export const getInitiativeFilesCount = (state) =>
  state.initiative.initiativeFilesCount;
export const getInitiativeUploadFilesProgress = (state) =>
  state.initiative.initiativeUploadFilesProgress;
export const getInitiativeFilesLoading = (state) =>
  state.initiative.initiativeFilesLoading;
export const getInitiativeTags = (state) => state.initiative.initiativeTags;
export const getInitiativeTagsLoading = (state) =>
  state.initiative.initiativeTagsLoading;
export const getInitiativeTagsError = (state) =>
  state.initiative.initiativeTagsError;

export default initiativesSlice.reducer;
