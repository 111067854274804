import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { PerPageTwenty } from "../../../Constants/Glopalconstants";

const initialState = {
  logsHistory: [],
  logsHistoryPage: 1,
  logsHistoryCount: 0,
  logsHistoryLoading: true,
  logsHistoryError: "",

  userLogsHistory: [],
  userLogsHistoryPage: 1,
  userLogsHistoryCount: 0,
  userLogsHistoryLoading: true,
  userLogsHistoryError: "",
  
  userLogAnalytic: [],
  userLogAnalyticLoading: true,
  userLogAnalyticError: "",
};

export const getLogsHistoryAsync = createAsyncThunk(
  "logsHistory/get",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.get(
        `${BASE_URL}/activity-log?page=${action.page}&perPage=${PerPageTwenty}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getUserLogsHistoryAsync = createAsyncThunk(
  "userLogsHistory/get",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.get(
        `${BASE_URL}/activity-log/user/${action.userId}?page=${action.page}&perPage=${PerPageTwenty}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const getUserLogAnalyticAsync = createAsyncThunk(
  "userLogsAnalytic/post",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.post(
        `${BASE_URL}/dashboard/user-analytics-data`,
        {
          ...action,
        },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

const logsHistorySlice = createSlice({
  name: "logsHistory",
  initialState,
  reducers: {
    resetLogsHistory: () => initialState,
    resetUserLogsHistory: (state) => {
      state.userLogsHistory = [];
      state.userLogsHistoryPage = 1;
      state.userLogsHistoryCount = 0;
      state.userLogsHistoryLoading = true;
      state.userLogsHistoryError = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogsHistoryAsync.pending, (state, action) => {
        state.logsHistoryLoading = true;
      })
      .addCase(getLogsHistoryAsync.fulfilled, (state, action) => {
        state.logsHistory = [...state.logsHistory, ...action.payload.logs];
        state.logsHistoryPage = action.payload.page;
        state.logsHistoryCount = action.payload.count;
        state.logsHistoryLoading = false;
      })
      .addCase(getLogsHistoryAsync.rejected, (state, action) => {
        state.logsHistoryLoading = false;
        state.logsHistoryError = action.payload?.message;
      })
      .addCase(getUserLogsHistoryAsync.pending, (state, action) => {
        state.userLogsHistoryLoading = true;
      })
      .addCase(getUserLogsHistoryAsync.fulfilled, (state, action) => {
        state.userLogsHistory = [
          ...state.userLogsHistory,
          ...action.payload.logs,
        ];
        state.userLogsHistoryPage = action.payload.page;
        state.userLogsHistoryCount = action.payload.count;
        state.userLogsHistoryLoading = false;
      })
      .addCase(getUserLogsHistoryAsync.rejected, (state, action) => {
        state.userLogsHistoryLoading = false;
        state.userLogsHistoryError = action.payload?.message;
      })
      .addCase(getUserLogAnalyticAsync.pending, (state, action) => {
        state.userLogAnalyticLoading = true;
      })
      .addCase(getUserLogAnalyticAsync.fulfilled, (state, action) => {
        state.userLogAnalyticLoading = false;
        state.userLogAnalytic = action.payload;
      })
      .addCase(getUserLogAnalyticAsync.rejected, (state, action) => {
        state.userLogAnalyticLoading = false;
        state.userLogAnalyticError = action.payload?.message;
      })
    
  },
});

export const { resetLogsHistory, resetUserLogsHistory } =
  logsHistorySlice.actions;

//Selectors
export const getLogsHistory = (state) => state.logsHistory.logsHistory;
export const getLogsHistoryPage = (state) => state.logsHistory.logsHistoryPage;
export const getLogsHistoryCount = (state) =>
  state.logsHistory.logsHistoryCount;
export const getLogsHistoryLoading = (state) =>
  state.logsHistory.logsHistoryLoading;
export const getLogsHistoryError = (state) =>
  state.logsHistory.logsHistoryError;

export const getUserLogsHistory = (state) => state.logsHistory.userLogsHistory;
export const getUserLogsHistoryPage = (state) =>
  state.logsHistory.userLogsHistoryPage;
export const getUserLogsHistoryCount = (state) =>
  state.logsHistory.userLogsHistoryCount;
export const getUserLogsHistoryLoading = (state) =>
  state.logsHistory.userLogsHistoryLoading;
export const getUserLogsHistoryError = (state) =>
  state.logsHistory.userLogsHistoryError;
export const getUserLogAnalyticLoading = (state) =>
  state.logsHistory.userLogAnalyticLoading;
export const getUserLogAnalytic = (state) =>
  state.logsHistory.userLogAnalytic;

export default logsHistorySlice.reducer;
