import BaseModal from "../BaseModal/BaseModal";
import "./NoPermission.scss";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../Store/slices/user";

const NoPermission = () => {
  const currentUser = useSelector(getCurrentUser);

  return (
    <BaseModal visible={true} headerTitle={"Upgrade to access CX"}>
      <div className={"no-permission"}>
        <h2 className={"no-permission--title"}>QuestionPro Feedbacks</h2>
        <p className={"no-permission--email"}>{currentUser?.emailAddress}</p>
        <p className={"no-permission--desc"}>
          QuestionPro Feedbacks is your one-stop solution for employee
          experience. It helps an organization to heighten employee engagement,
          affiliation, and productivity while enhancing important drivers like
          agility, collaboration, and innovation.
        </p>
        <img
          width={520}
          src="https://www.questionpro.com/userimages/site_media/app/locks/WORKFORCE_MODULE.png"
          alt="Logo"
        />
      </div>
    </BaseModal>
  );
};

export default NoPermission;
