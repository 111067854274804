import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import { PerPageTwenty } from "../../../Constants/Glopalconstants";
import { taskStatus, taskStatusType } from "../../../Constants";

const initialState = {
  tasks: {},
  tasksCount: {},
  tasksLoading: true,
  tasksPage: 1,

  statusIndex: 0,
  status: taskStatus[0],

  taskWithMilestones: [],
  taskWithMilestonesCount: 0,
  taskWithMilestonesLoading: true,

  error: {},
};

let controller;

export const getTaskAsync = createAsyncThunk(
  "task/get",
  async (action, state) => {
    controller = new AbortController();
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/task/${action.id}/initiative?page=${action.page}&perPage=${PerPageTwenty}&status=${action.status}`,
      {
        signal: controller.signal,
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);
export const createTaskAsync = createAsyncThunk(
  "task/create",
  async (action, state) => {
    controller = new AbortController();
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/task`,
      { ...action },
      {
        signal: controller.signal,
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getInitCheckpointFilterAsync2 = createAsyncThunk(
  "checkpoint/post",
  async (action, state) => {
    controller = new AbortController();
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/checkpoint/status`,
      {
        status: action.status,
        lane: action.lane,
        tags: action.tags,
        owners: action.owners,
      },
      {
        signal: controller.signal,
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return {
      initiatives: response.data.data || [],
      months: response.data.months || [],
      page: response.data.page,
      lane: response.data.lane,
      status: action.status,
    };
  }
);

export const updateTaskAsync = createAsyncThunk(
  "task/update",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/task/${action.id}`,
      { ...action.task },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);
export const deleteTaskAsync = createAsyncThunk(
  "task/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/task/${action.id}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const changeTaskStatus = createAsyncThunk(
  "taskStatus/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/task/${action.id}/status`,
      { status: action.status },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getTaskWithMilestonesAsync = createAsyncThunk(
  "taskWithMilestones/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/task/milestone/${action.id}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    resetTasks: () => initialState,
    abortRequest() {
      if (controller) {
        controller.abort();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskAsync.pending, (state, action) => {
        state.error = {};
        state.tasksLoading = true;
      })
      .addCase(getTaskAsync.fulfilled, (state, action) => {
        const rows = state.tasks[taskStatus[state.statusIndex]] || [];
        const allRows = [...rows, ...action.payload.rows];
        state.tasksCount = {
          ...state.tasksCount,
          [taskStatus[state.statusIndex]]: action.payload.count,
        };
        state.tasks = {
          ...state.tasks,
          [taskStatus[state.statusIndex]]: allRows,
        };
        state.tasksPage += 1;
        if (allRows.length >= action.payload.count) {
          state.status = taskStatus[state.statusIndex + 1];
          state.statusIndex += 1;
          state.tasksPage = 1;
        }
        state.tasksLoading = false;
      })
      .addCase(getTaskAsync.rejected, (state, action) => {
        state.tasksLoading = false;
        state.error.tasks = "Something went wrong";
      })

      .addCase(createTaskAsync.fulfilled, (state, action) => {
        state.tasksCount = {
          ...state.tasksCount,
          pending: (state.tasksCount.pending += 1),
        };
        state.tasks = {
          ...state.tasks,
          pending: [action.payload, ...state.tasks.pending],
        };
      })
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        const status = taskStatusType[action.payload.status];
        state.tasks[status] = state.tasks[status].filter(
          (task) => task.id !== action.payload.id
        );
      })
      .addCase(updateTaskAsync.fulfilled, (state, action) => {
        const status = taskStatusType[action.payload.status];

        const taskIndex = state.tasks[status].findIndex(
          (task) => task.id === action.payload.id
        );
        state.tasks[status][taskIndex] = action.payload;
      })
      .addCase(changeTaskStatus.fulfilled, (state, action) => {
        const oldStatus = taskStatusType[action.payload.oldStatus];
        const status = taskStatusType[action.payload.status];

        state.tasks[oldStatus] = state.tasks[oldStatus].filter(
          (task) => task.id !== action.payload.id
        );

        if (state.tasks[status]) {
          state.tasks[status] = [action.payload, ...state.tasks[status]];
        }
      })
      .addCase(getTaskWithMilestonesAsync.pending, (state, action) => {
        state.error = {};
        state.taskWithMilestonesLoading = true;
      })
      .addCase(getTaskWithMilestonesAsync.fulfilled, (state, action) => {
        state.taskWithMilestones = action.payload.tasks;
        state.taskWithMilestonesLoading = false;
      })
      .addCase(getTaskWithMilestonesAsync.rejected, (state, action) => {
        state.taskWithMilestonesLoading = false;
        state.error.taskWithMilestones = "Something went wrong";
      });
  },
});
export const { resetTasks, abortRequest } = tasksSlice.actions;

//Selectors
export const getTasks = (state) => state.tasks.tasks;
export const getTasksCount = (state) => state.tasks.tasksCount;
export const getTasksLoading = (state) => state.tasks.tasksLoading;
export const getTasksPage = (state) => state.tasks.tasksPage;
export const getTaskWithMilestones = (state) => state.tasks.taskWithMilestones;
export const getStatus = (state) => state.tasks.status;
export const getTaskWithMilestonesCount = (state) =>
  state.tasks.taskWithMilestonesCount;
export const getTaskWithMilestonesLoading = (state) =>
  state.tasks.taskWithMilestonesLoading;
export const getTaskError = (state) => state.tasks.error;

export default tasksSlice.reducer;
