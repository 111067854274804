import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";

const initialState = {
  initiativePeople: [],
  initiativePeopleLoading: true,
  initiativePeopleError: "",
};

export const getInitiativePeopleRoleAsync = createAsyncThunk(
  "initiativePeople/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.get(
        `${BASE_URL}/initiative-people/initiative/${action.initID}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const createInitiativePeopleRoleAsync = createAsyncThunk(
  "initiativePeople/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.post(
        `${BASE_URL}/initiative-people`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteInitiativePeopleRoleAsync = createAsyncThunk(
  "initiativePeople/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    try {
      const response = await axios.delete(
        `${BASE_URL}/initiative-people/${action.peopleId}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

const peopleRoleSlice = createSlice({
  name: "peopleRole",
  initialState,
  reducers: {
    resetPeopleRole: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitiativePeopleRoleAsync.pending, (state, action) => {
        state.initiativePeopleLoading = true;
      })
      .addCase(getInitiativePeopleRoleAsync.fulfilled, (state, action) => {
        state.initiativePeople = action.payload;
        state.initiativePeopleLoading = false;
      })
      .addCase(getInitiativePeopleRoleAsync.rejected, (state, action) => {
        state.initiativePeopleLoading = false;
        state.initiativePeopleError = action.payload;
      })
      .addCase(createInitiativePeopleRoleAsync.fulfilled, (state, action) => {
        state.initiativePeople[action.payload?.type] = [
          action.payload.person,
          ...state.initiativePeople[action.payload?.type],
        ];
      })

      .addCase(deleteInitiativePeopleRoleAsync.fulfilled, (state, action) => {
        state.initiativePeople[action.payload?.type] = state.initiativePeople[
          action.payload?.type
        ].filter((el) => el.id !== action.payload?.id);
      });
  },
});

export const { resetPeopleRole } = peopleRoleSlice.actions;

export const getInitiativePeople = (state) => state.peopleRole.initiativePeople;
export const getInitiativePeopleLoading = (state) =>
  state.peopleRole.initiativePeopleLoading;
export const getInitiativePeopleError = (state) =>
  state.peopleRole.initiativePeopleError;

export default peopleRoleSlice.reducer;
