export const BASE_URL = process.env.REACT_APP_API;
export const AUTH_URL = process.env.REACT_APP_AUTHORIZATION_URL;
export const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_ENDPOINT;
export const AWS_URL = process.env.REACT_APP_AWS_STORAGE_URL;
export const AWS_SINGER = process.env.REACT_APP_SINGER_URL;
export const AWS_KEY = process.env.REACT_APP_AWS_KEY;
export const AWS_BUCKET = process.env.REACT_APP_BUCKET;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const TOKEN_NAME = "outerloop-token";
export const TOKEN_SERVER_URL = process.env.REACT_APP_TOKEN_SERVER_URL;
